﻿/// <reference path="../../cfi-main">
.report-tagged-split-text {

/*    @each $theme, $color in $report-themes {
        &.theme-#{$theme} {
            .report-tagged-split-text-tag {
                background-color: $color;
            }

            .report-btn-outline {
                color: $color;
                border-color: $color;
            }
        }
    }*/

    &.split-image-left {

        .report-tagged-split-text-copy {
            order: 2;
            padding-right: 0;
            padding-left: $gap-xxl;

            @media #{$report-tablet} {
                padding-left: $gap;
            }

            @media #{$report-mobile} {
                padding-left: 0;
            }
        }

        .report-tagged-split-text-image {
            order: 1;
            padding-top: 0;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;

        @media #{$report-mobile} {
            flex-direction: column;
        }

        &.full-width {
            @media #{$report-mobile} {
                padding-left: $gap-sm;
            }
        }

        &.horizontal-scroll {

            .report-tagged-split-text-copy {
                overflow-x: auto;
                padding-bottom: $gap;
            }
        }
    }

    &-copy {
        flex: 1;
        padding-right: $gap-xxl;

        @media #{$report-tablet} {
            padding-right: $gap;
        }

        @media #{$report-mobile} {
            order: 2;
            padding-right: 0;
        }

        h2 + .h3-style {
            margin-top: $gap-sm;
        }

        h2 + p,
        .h3-style + p {
            margin-top: $report-heading-margin;
        }

        p {
            margin-bottom: $gap;
        }

        table {
            margin-top: $gap-md;
        }

        .report-btn-outline {
            margin-top: $gap-sm;
        }
    }

    &-tag:nth-child(n) {
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        margin-bottom: $gap-md;
        padding: $sliver $gap-sm;
    }

    &-image {
        position: relative;
        flex: 1;
        padding-top: $gap-xl;

        @media #{$report-mobile} {
            order: 1;
            margin-bottom: $gap;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &-contain img {
            object-fit: contain;
        }
    }
}
