﻿/// <reference path="../../cfi-main.scss">

.pagination {
    @include remove-list-format;
    text-align: center;

    li {
        display: inline-block;
        margin: 0 5px;


        &:not(.nav) {
            @include color-links($dark-gray, $blue);

            @media #{$media-mobile-view} {
                display: none;
            }

            > a {
                padding: $button-dropdown-font-padding 20px;
            }

            &.medium {
                > a {
                    @include gray-border;

                    &:hover {
                        color: $dark-gray;
                        cursor: default;
                    }
                }
            }
        }
    }

    .nav {

        @media #{$media-mobile-view} {
            margin: 0 15px;
        }

        @media #{$media-xs} {
            display: block;
            margin: 0;
        }

        > a {
            @include btn-border-collapsed;
            text-align: center;

            @media #{$media-xs} {
                display: block;
            }

            @media #{$media-sm-min} {
                width: 160px;
            }
        }

        &.prev {
            @media #{$media-xs} {
                margin-bottom: ($v-spacing-sm);
            }

            @media #{$media-sm-min} {
                text-align: right;
            }

            @media #{$media-md-min} {
                margin-right: $grid-gutter-width;
            }

            @media #{$media-lg-min} {
                margin-right: $grid-gutter-width-double;
            }

            > a {
                @include btn-prev;
            }
        }

        &.next {
            > a {
                @include btn-next;
            }

            @media #{$media-md-min} {
                margin-left: $grid-gutter-width;
            }

            @media #{$media-lg-min} {
                margin-left: $grid-gutter-width-double;
            }
        }

        .disabled {
            pointer-events: none;
            cursor: default;
        }
    }
}
