﻿/// <reference path="../cfi-main.scss">

.careers-page {
    @include v-spacing-bottom;

    fieldset {
        border: 0;
        margin: 0;
        padding: 0;

        > div {
            margin-bottom: $v-spacing-xs;
        }
    }

    legend {
        @include h2;
        color: $blue;
        margin-bottom: $v-spacing-sm;
    }

    label {
        @include h5;
        display: inline-block;
        vertical-align: middle;

        &.SA-LR {
            margin-right: 10px;
        }
    }

    input[type="checkbox"],
    input[type="radio"] {
        display: inline-block;
        margin-right: 2px;
        vertical-align: middle;
    }

    input[type="submit"] {
        @include transition-all-default;
        background: none;
        border: 0;
        padding: 0;
        @include btn-border-collapsed;
    }

    a.info {
        @include arrow-link;
    }

    #info {
        display: none;
    }
    /* search results */
    .Lst-CriteriaLabel {
        @include h6;
    }

    .Lst-CrtLabel {
        @include h5;
    }

    #TableJobs {
        //"responsive" table (boostrap styles)
        min-height: 0.1%;
        overflow-x: auto;

        @media #{$media-mobile-view} {
            overflow-y: hidden;
            width: 100%;
        }

        table {
            max-width: 100%;
            width: 100%;
            table-layout: fixed;
            overflow: hidden;

            > tbody > tr:nth-of-type(odd) {
                background: $lightest-gray;
            }
        }

        caption {
            @include h5;
            text-align: left;
        }


        .Lst-CountLabel {
            padding: $v-spacing-sm 0 $v-spacing-xs;
        }

        th, td {
            padding: 10px;
            text-align: left;

            &.Lst-ResNav {
                background: $white;
                padding-left: 0;
                padding-top: $v-spacing-sm;
            }

            &:nth-of-type(1) {
                width: 30%;
            }

            &:nth-of-type(6) {
                width: 10%;
            }
        }

        .Lst-ResNav {
            ul {
                display: inline-block;
            }

            li {
                display: inline;
                list-style: none;

                &:after {
                    content: "  /  "
                }

                &:last-child:after {
                    content: "";
                }
            }
        }

        th {
            @include h4;
            @include color-links($black, $blue);
        }
    }
    /* hide brackets around links */
    #bottomact,
    #JD-Actions,
    #searchagentbutton {
        margin-top: $v-spacing-sm;

        > div {
            margin-bottom: 10px;
        }

        .linkbracket {
            display: none;
        }
    }

    #bottomact {
        a {
            @include arrow-link;
        }
    }

    span.NewPopUp {
        display: none;
    }
    /* job details */
    #JDescContent {
        /* using title as h1*/
        top: -30px;

        @media #{$media-desktop-view} {
            top: -60px;
        }
    }

    .JD-Title {
        @include h1;
        margin-bottom: $v-spacing-sm;
    }

    .JD-ApplyWithLinkedIn {
        margin-bottom: $v-spacing-sm;

        .tlk-apply-button-wrapper {
            @include transition-all-default;
            @include btn-border-collapsed;

            &:hover {
                .tlk-apply-button-title {
                    @include transition-all-default;
                    color: $white !important;
                }
            }

            .tlk-apply-button-title {
                @include font-roboto-light;
            }
        }

        .tlk-apply-button-border {
            background: transparent !important;
            border: 0 !important;
            padding: 0 !important;
        }
    }

    #JD-TopParams,
    #JD-AllFields {
        @include make-row;

        > div {
            @include make-md-column(9);
            margin-bottom: $v-spacing-sm;
        }

        h4.JD-HDLabel,
        h5.JD-FieldLabel {
            @include h6;
            text-transform: none;
        }
    }

    #JD-Actions {
        #JD-ActApplyDirect,
        #JD-ActPrintPage,
        #JD-ActSendJob,
        #JD-ActBack {
            a {
                @include arrow-link;
            }
        }

        #JD-ActApplyDirect {
            margin-bottom: $v-spacing-sm;
        }
    }

    .JD-socialNetworks-bottom {
        .at-icon-wrapper {
            display: inline-block;
            margin-right: 5px;
            vertical-align: middle;
        }
    }
    /* job alerts */
    #SAgLogSet,
    #sag-submitbutton {
        margin-top: $v-spacing-sm;
    }

    #SAgLogSet {
        label {
            margin-right: 5px;
        }
    }

    .chpfailedlog {
        color: red;
        font-size: 12px;
    }

    #sag-actions {
        #searchagentbutton {
            a {
                &:before {
                    @include budicon;
                    content: $bi-var-caret-left;
                    font-size: 13px;
                    margin-right: 5px;
                }
            }
        }
    }
    /* login page */
    #chpLogButton {
        margin-top: $v-spacing-sm;
    }

    #chpLogLabelUser,
    #chpLogLabelPassw,
    #chpLogTextPassw {
        margin-bottom: 0;
    }

    #chpLogForgot {
        margin-top: $v-spacing-sm;

        a {
            @include arrow-link;
        }
    }
}
