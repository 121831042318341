﻿/// <reference path="../../cfi-main.scss">

.universal-navigation {
    @media #{$media-desktop-view} {
        height: $nav-top-bar-height;
        text-align: right;
    }

    > ul {
        @include remove-list-format;

        @media #{$media-desktop-view} {
            @include flex-v-align;
            height: $nav-top-bar-height;
            justify-content: flex-end;
        }

        > li {
            @media #{$media-desktop-view} {
                margin-left: $grid-gutter-width;
            }

            > a {
                @include nav-link-style;

                @media #{$media-desktop-view} {
                    @include font-roboto-medium;
                    font-size: 11px;
                }
            }
        }
    }
}


.fixed-header {
    .universal-navigation {
        height: $nav-fixed-height;

        ul {
            height: $nav-fixed-height;
        }
    }
}
