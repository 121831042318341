﻿/// <reference path="../cfi-main.scss">

.news-landing-page {
    @include page-margin-bottom;

    .news-filters {
        h3 {
            margin: $v-spacing-sm 0 ($v-spacing-sm / 2);

            @media #{$media-desktop-view} {
                margin: $v-spacing-lg 0 ($v-spacing-sm / 2);
                padding-left: 10px;
            }
        }
    }

    .news-list {
        @include make-md-column(8); 

        ul {
            @include remove-list-format;

            li {
                @include gray-border-bottom;
                margin-bottom: $v-spacing-sm;
                padding-bottom: $v-spacing-sm; 
            }
        }

        .image {
            @include make-sm-column(4);

            img {
                @include img-responsive;
            }

            @media #{$media-mobile-view} {
                margin-bottom: $v-spacing-sm;
            }
        }

        .content {
            @include make-sm-column(6);
            @include make-lg-column(5);

            h3 {
                color:$blue;
                margin: 20px 0 10px;
            }

            .teaser {
                color:$dark-gray;
                margin-bottom: ($v-spacing-sm / 2);
            }

            .arrow-link {
                @include arrow-link;
            }
        }

        .date {
            @include make-sm-column(2);
            @include make-lg-column(3);

            @media #{$media-mobile-view} {
                margin-top: $v-spacing-xs;
            }

            @media #{$media-desktop-view} {
                text-align: right;
            }
        }

        .date,
        .category {
            @include font-roboto-medium;
            color: $dark-gray;
            font-size: 11px;
            line-height: 11px;
        }

        .news-item {
            @include make-row;
            display: block;

            &:hover {
                cursor: pointer;

                .arrow-link {
                    color: $blue;
                    cursor: pointer;

                    &:after {
                        color: $blue;
                        margin-left: 15px;
                    }
                }
            }
        }

        .btn-border.load-more {
            display: block;
            margin-top: $v-spacing-sm;
            text-align: center;

            &:after {
                @include budicon;
                content: $bi-plus;
                font-size: 12px;
                margin-left: 5px;
                top: 0;
            }
        }
    }

    .media {
        @include make-md-column(4);
        color: $dark-gray;
        font-size: 15px;

        @media #{$media-sm-max} {
            margin-top: $v-spacing-sm;
        }

        > div {
            @include right-rail-styling;
        }

        .section {
            margin-bottom: $v-spacing-sm;

            h3 {
                color: $dark-gray;
                margin-bottom: $v-spacing-sm;
            }

            &.resources {
                @include color-links($dark-gray, $blue);

                ul {
                    @include remove-list-format;

                    li {
                        margin-bottom: 5px;

                        a {

                            &.icon {
                                &:after {
                                    @include budicon;
                                    margin-left: 8px;
                                    top:-1px;
                                }

                                &.download {
                                    &:after {
                                        content: $bi-var-web-download;
                                    }
                                }

                                &.link {
                                    &:after {
                                        content: $bi-var-out-link;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
