﻿/// <reference path="cfi-main.scss">
// dropdowns from bootstrap
@import '../../CFI/libs/bootstrap/stylesheets/bootstrap/_component-animations.scss';

* {
    @include box-sizing(border-box);
}

*:before,
*:after {
    @include box-sizing(border-box);
}

body {
    @include font-roboto-light;
    background-color: #fff; // sets color in Episerver edit mode
    color: $gray-dark;
    font-size: $base-font-size;
    line-height: 1.6em;
}

h1, h2, h3, p {
    margin: 0;
}

p, .p-style {
    @include p;
}

h1, .h1-style {
    @include h1;
}

h2, .h2-style {
    @include h2;
}

h3, .h3-style {
    @include h3;
}

h4, .h4-style {
    @include h4;
}

h5, .h5-style {
    @include h5;
}

h6, .h6-style {
    @include h6;
}

.small {
    @include p;
    font-size: 0.825em;
    line-height: 1.4;
}

a {
    @include transition(all, ease, 0.4s);
    color: $green-2021;
    text-decoration: none;

    &:hover {
        color: $bright-blue-2021;
        text-decoration: none;
    }
}

b, strong {
    font-weight: $font-weight-bold;
}

.medium {
    @include font-roboto-medium;
}

.uppercase {
    text-transform: uppercase;
}

.page-content {
    min-height: 500px;
    padding-top: $mobile-header-height;

    @media #{$media-desktop-view} {
        padding-top: $nav-total-height;
    }
}

.container,
%container {
    @include container-fixed;

    @media #{$media-desktop-view} {
        width: $container-sm;
    }

    @media #{$media-desktop-view} {
        width: $container-md;
    }

    @media #{$media-lg-min} {
        width: $container-lg;
    }
}

.container-narrow,
%container-narrow {
    @include container-fixed;
    max-width: $container-narrow;
}

.row {
    @include make-row;
}

.flex-v-align {
    @include flex-v-align;
}

/* Styled Links */
.arrow-link {
    @include arrow-link;
}

.arrow-link-white {
    @include arrow-link-white;
}

.arrow-link-black {
    @include arrow-link-black;
}

.arrow-link-green {
    @include arrow-link-green;
}

.btn-border {
    @include btn-border-collapsed;
}

/* Angular Animations */
.animate-load {
    transition: 1s ease all;

    &.ng-enter {
        transition: 1s ease all;
        opacity: 0;
    }

    &.ng-enter.ng-enter-active {
        opacity: 1;
    }

    &.ng-leave {
        transition: 1s ease all;
        opacity: 1;
    }

    &.ng-leave.ng-leave-active {
        opacity: 0;
    }
}

/* Angular Hide Elements Before Binding */
[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}


/* Content Fields */
.cf-content {
    h1, h2, h3 {
        margin-bottom: $v-spacing-xs;
    }

    h4, h5, h6 {
        margin-bottom: 5px;
    }

    p {
        margin-bottom: $v-spacing-sm;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h3 {
        color: $blue;
    }

    ul {
        margin: 0 0 $v-spacing-sm;
        padding: 0 0 0 20px;

        li {
            margin: 10px 0 0;

            &:first-child {
                margin: 0;
            }
        }
    }

    img {
        @include img-responsive;
    }
}

%un-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    display: inline;
}

.bold {
    font-weight: $font-weight-bold;
}