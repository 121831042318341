﻿/// <reference path="../../cfi-main.scss">

.breadcrumb {
    @include color-links($blue, $black);
    background:$lightest-gray;
    display: none;
    left:0;
    padding: 25px 0;
    position:absolute;
    width:100%;

    @media #{$media-desktop-view} {
        display: block;
    }

    ul {
        @include remove-list-format;

        li {
            float:left;
            margin-right:10px;

            &:after {
                @include budicon;
                content: $bi-var-caret-right;
                font-size:12px;
                margin-left:10px;
                top:0;
            }

            &:last-child { 
                &:after {
                    content: " ";
                }
            }
            
            .disabled {
                color:$black;

                &:hover {
                    cursor:default;
                }
            }
        }
    }

}
