﻿/// <reference path="../report">

.report-fwcta {
    &-block {
        @include module-padding;
        background-color: rgba($report-community, 0.1);

        @each $theme, $color in $report-themes {
            &.theme-#{$theme} {
                color: $color;
            }
        }
    }

    &-text {
        > * + * {
            margin-top: 1em;
        }
    }

    &-cta:nth-child(n) {
        margin-top: $report-heading-margin * 0.66;
    }
}
