﻿/// <reference path="../../cfi-main">

// The majority of styles for this block are handled on the carousel and card component styles

$_video-carousel-play-size: 117px;
$_video-play-icon-size: 2.75rem;

.video-carousel {
    @include module-padding;

    &-play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: $_video-carousel-play-size;
        width: $_video-carousel-play-size;
        z-index: 1;

        &-circle {
            border-radius: 999em;
            opacity: 0.5;
            background-color: $green;
            box-shadow: 1px 3px 14px 0 rgba($gray-dark,0.36);
            height: 100%;
            width: 100%;
            display: block;
        }

        &-triangle {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            border-top: $_video-play-icon-size/2 solid transparent;
            border-left: $_video-play-icon-size solid $white;
            border-bottom: $_video-play-icon-size/2 solid transparent;
            opacity: 0.8;
            transform: translate(-42%, -50%);
        }
    }

    &-item {
        $self: &;

        &.is-active:not(.is-origin) {
            #{$self}-button-thumb {
                padding-bottom: percentage(407 / 517);
                opacity: 1;
            }

            .video-carousel-item-description {
                opacity: 1;
            }
        }
    }

    &-item-button {
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        display: block;
        text-decoration: none;
        width: 100%;

        &-frame {
            position: relative;
            width: 100%;
            overflow: hidden;
            height: 0;
            padding-bottom: percentage(407 / 517);
        }

        &-thumb {
            position: absolute;
            width: 100%;
            overflow: hidden;
            height: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding-bottom: percentage(336 / 517);
            border-radius: 0;
            box-shadow: 1px 2px 10px 0 rgba(23,20,34,0.15);
            transition: .2s ease all;
            opacity: .5;

            img {
                position: absolute;
                width: 100%;
                height: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                height: auto;

                @supports(object-fit: cover) {
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    &-item-icon {
        display: block;
        background-color: $green;
        opacity: .5;
        height: $_video-carousel-play-size;
        width: $_video-carousel-play-size;
    }

    &-item-description {
        margin-top: $v-spacing-sm;
        text-align: center;
        opacity: 0;
        transition: 0.3s ease all;
    }

    &-item-iframe iframe, &-item-native video {
        width: 100%;
    }
}
