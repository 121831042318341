﻿/// <reference path="../report">

.report-graphic-sidebar {
    padding: $gap-xl / 2;
    background: rgba($report-safety, 0.1);
    font-weight: $font-weight-bold;
    width: 100%;

    .h3-style,
    .h4-style {
        color: $report-safety;
    }

    ul {
        @extend %logo-bullets;
        padding-left: 1.5em;
        color: $report-workforce;

        li {
            padding-left: 0.75em;

            & + li {
                margin-top: $gap-sm;
            }
        }
    }

    &-main {
        display: flex;
        align-items: flex-start;
        gap: $gap;
        margin-top: $report-heading-margin;

        @media #{$media-sm-max} {
            flex-direction: column;
        }

        & > * {
            flex: 1 1 auto;
            width: calc(50% - #{$gap / 2});

            @media #{$media-md-max} {
                width: auto;
            }
        }
    }

    &-cta {
        margin: $report-heading-margin auto;
        text-align: center;
    }

    &-list {
    }

    &-item {
        display: flex;
        gap: $gap-sm;

        & + * {
            margin-top: $report-heading-margin;
        }
    }

    &-icon {
        flex: 0 0 100px;
    }

    &-text {
        flex: 1 1 auto;

        p {
            @include p-small;
        }
    }

    &-title {
        font-weight: $font-weight-bold;

        @at-root {
            @each $theme, $color in $report-themes {
                .theme-#{$theme} & {
                    color: $color;
                }
            }
        }
    }
}
