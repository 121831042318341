﻿/// <reference path="../../cfi-main.scss">

.site-search {
    display: flex;
    align-items: center;

    @media #{$media-mobile-view} {
        font-size: 1.5rem;
    }

    @media #{$media-desktop-view} {
        height: $nav-bottom-bar-height;
    }

    &-inner {
        position: relative;
        flex: 1 1 auto;

        @media #{$media-mobile-view} {
            border-bottom: 1px solid $gray;
        }
    }

    &-input {
        width: 100%;
        border: 1px solid transparent;
        background: $lightest-gray;
        padding: $grid-gutter-width / 4;
        font-size: 1em;

        @media #{$media-mobile-view} {
            padding: $grid-gutter-width-half;
        }
    }
}

.search-button {
    background: transparent;
    font-size: 1em;
    border: none;
    padding: 0;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 47%;
    right: $grid-gutter-width / 2;
    transform: translateY(-50%);

    &-icon {
        @include site-search-icon($gray, $dark-gray);
        top: -3px;
    }
}
