﻿/// <reference path="../cfi-main.scss">

.text-block {
    @include general-page-block-spacing;

    .text-content {
        @include make-sm-column(10);
        @include make-sm-column-offset(1);
        @include make-lg-column(8);
        @include make-lg-column-offset(2); 
    }
     
    .accordion {
        margin: $grid-gutter-width 0 0;

        @media #{$media-sm-min} {
            margin: $grid-gutter-width-double auto;
            width: 66.6666667%; 
        } 

        @media #{$media-lg-min} {
            width: 50%;
        }
    }
}
