﻿/// <reference path="../report">
.report,
.refresh {

    .stats-list:nth-child(n) {
        background-color: $white;

        h2 {
            margin-bottom: $gap;
        }
    }

    .stats-list-item:nth-child(n) {
        h3 {
            margin: $gap-lg 0 $space 0;
        }
    }

    .stats-list-text:nth-child(n) {
        padding-top: 0;
    }

    .stats-list-link:nth-child(n) {

        .btn-border {
            @extend %report-btn-outline;
        }
    }
}