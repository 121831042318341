﻿/// <reference path="../cfi-main.scss">

.image-text-block {
   @include general-page-block-spacing;

    .text-column {
        @include make-sm-column(6);
        @include make-md-column(5);

        @media #{$media-xs} {
            margin-top: $v-spacing-sm;
        }
    }

    .image-column {
        @include make-sm-column(6);
    }

    &.text-right {
        .text-column {
            @include make-md-column-offset(1);
        }
    }

    &.text-left {
        .image-column {
            @include make-md-column-offset(1);

            @media #{$media-desktop-view} {
                float: right !important;
            }
        }
    }

    .image {
        @include img-responsive;
    }

    .video {
        background-color: $video-background-color;
        display: block;
        height: auto;
        width: 100%;
    }
}
