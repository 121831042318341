﻿/// <reference path="../../cfi-main.scss">

.back-to-top {
    position: relative;

    .back-to-top-inner {
        @include fade-out(0.4s);
        background: rgba(0,0,0,.8);
        bottom: 60px;
        color: $white;
        display: inline-block;
        height: 60px;
        position: fixed;
        right: 30px;
        z-index: 10;
        width: 60px;

        @media #{$media-desktop-view} {
            right: 60px;
        }

        @media #{$media-lg-min} {
            right: 100px;
        }

        &.back-to-top-visible {
            @include fade-in(0.4s);
        }

        &:after {
            @include budicon;
            content: $bi-var-arrow-up;
            font-size: 22px;
            left: 50%;
            margin-left: -10px;
            margin-top: -10px;
            position: absolute;
            top: 50%;
        }

        &:hover {
            background: $black;
            cursor: pointer;
        }
    }
}
