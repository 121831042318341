﻿/// <reference path="../../cfi-main">
$report-landing-hero-height: 50vh;
$report-landing-hero-height-mobile: 40vh;

.report-landing,
.refresh-home {

    &-hero {
        position: relative;
        height: $report-landing-hero-height;

        @media #{$report-tablet} {
            height: $report-landing-hero-height-mobile;
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: rgb(84,206,50);
                background: $report-green-gradient;
                opacity: .6;
            }
        }

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            color: $white;
        }

        &-subtitle {
            margin-top: $gap-sm;
        }
    }
}