﻿/// <reference path="../../cfi-main.scss">

.horizontal-filter-container {
    position: relative;

    @media #{$media-mobile-view} {
        margin-bottom: $v-spacing-sm;
    }

    .mobile-filter-toggle {
        @include btn-border-toggle($display: block);

        @media #{$media-desktop-view} {
            display: none;
        }

        &.collapsed {
            @media #{$media-desktop-view} {
                display: none;
            }
        }
    }

    @media #{$media-mobile-view} {
        .collapse,
        .collapsing {
            position: absolute;
            width: 100%;
            z-index: 10;
        }
    }

    ul {
        @include remove-list-format;

        @media #{$media-mobile-view} {
            @include blue-dropdown-styles($element: "list");
        }

        @media #{$media-desktop-view} {
            @include clearfix;
            @include gray-border-top;
            display: block !important; //incase user resizes from mobile to desktop with menu hidden
            margin-bottom: $v-spacing-sm;

            li {
                float: left;

                @media #{$media-desktop-view} {
                    margin-right: 20px;
                }

                a {
                    border-top: transparent 1px solid;
                    color: $dark-gray;
                    display: block;
                    font-size: 15px;
                    line-height: 15px;
                    padding: 20px 10px;
                    position: relative;
                    top: -1px;

                    &:hover,
                    &.active {
                        border-color: $blue;
                        color: $blue;
                        cursor: pointer;
                    }
                }
            }
        }

        @media #{$media-lg-min} {
            margin-bottom: $v-spacing-lg;
        }
    }
}

.unfilter-buttons { 

    button {
        @include transition-all-default;
        @include gray-border;
        background: 0;
        font-size: 15px;
        margin: 0 12px 20px 0;
        outline: 0;
        padding: 12px 40px 12px 12px;
        position: relative;
        min-width: 100px;
        text-align: left;

        &:after {
            @include transition-all-default;
            @include budicon;
            content: $bi-circle-close;
            color: $light-gray;
            float: right;
            margin-top: -8px;
            position: absolute;
            right: 12px;
            top: 50%;
        }

        &:hover {
            background: $blue;
            border-color: $blue;
            color: $white;
            cursor: pointer;

            &:after {
                color: $white;
            }
        }
    }
}
