﻿/// <reference path="../../cfi-main.scss">

/* article layout, small left sidebar */
.article-layout {
    @include make-row;

    .left {
        @include make-md-column(3);
        @include make-lg-column(2);

        @media #{$media-mobile-view} {
            margin-bottom: $v-spacing-sm;
        }
    }

    .right {
        @include make-md-column(9);
        @include make-lg-column(10);
    }
    /* variation if there's no left column */
    &.no-left-content {
        .right {
            @include make-md-column-offset(3);
            @include make-lg-column-offset(2);
        }
    }
}
