﻿/// <reference path="../../cfi-main.scss">

.collapse,
.collapsing {
    &.mobile-collapse {
        @media #{$media-desktop-view} {
            display: block;
            height: auto !important;
        }
    }
}

.accordion {
    @include gray-border-top;

    .panel {
        @include gray-border-bottom;
    }

    .panel-heading {
        h3 {
            @include font-roboto;
            @include color-links($green, $blue);
            font-size:15px; 
            margin: 0;

            a {
                display: block;
                padding: 15px 0;
                position: relative;

                &:after {
                    @include budicon;
                    @include transition(ease, transform, 0.4s);
                    color: $gray;
                    content: $bi-var-caret-down;
                    font-size: 18px;
                    margin-top: -9px;
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: rotate(180deg);
                }

                &.collapsed {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    .panel-body {
        > div {
            margin-bottom: $v-spacing-sm;
        }

        .arrow-link {
            margin-top: $v-spacing-sm;
        }
    }
}