﻿/// <reference path="../cfi-main.scss">

.quote-block {
    @include general-page-block-spacing;
    background: $lightest-gray;
    text-align: center;

    .quote-content {
        font-size: 24px;
        line-height: 1.5em;

        @media #{$media-desktop-view} {
            font-size: 42px;
        }
    }
    .quote-attribution {
        margin:20px 0 0;
        padding-top:20px;  
        position:relative;

        &:before {
            background: $dark-gray;
            content: " ";
            display: block;
            height: 1px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            top:0;
            width: 25px;
        }
    }
}
