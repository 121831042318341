﻿/// <reference path="../cfi-main.scss">

.three-column-text-callout-block {

    .three-column-layout {
        margin: 0;

        > ul {
            @include make-row;
            list-style-type: none;
            padding-left: 0;

            @media #{$media-desktop-view} {
                display: flex;
                flex-wrap: nowrap;
            }

            > li {
                @include make-md-column(4);
            }
        }
    }

    .three-column-heading {
        color: $blue;
        margin-bottom: 10px;
    }

    @media #{$media-mobile-view} {
        .three-column-layout {
            > ul > li {
                margin-top: $v-spacing-sm;

                &:first-child {
                    margin-top: $v-spacing-sm;

                    @media #{$media-mobile-view} {
                        margin-top: 0;
                    }
                }
            }
        }

        .arrow-link {
            margin-top: ($v-spacing-sm / 2);
        }
    }

    @media #{$media-desktop-view} {
        padding: $v-spacing-xs 0;

        .three-column-layout {
            > ul > li {
                padding-bottom: $v-spacing-sm;
            }
        }

        .arrow-link {
            bottom: 0;
            left: 15px;
            position: absolute;
        }
    }
}
