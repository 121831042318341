﻿.report-fixed-row-callout {

    &-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $gap-md;
        text-align: center;
        padding-bottom: $gap-xl;

        @media #{$report-tablet} {
            padding-bottom: $gap-lg;
        }

        @media #{$report-mobile} {
            padding-bottom: $gap;
        }

        h2 {
            margin-bottom: $report-heading-margin - $gap-md;
        }
    }

    &-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-template-rows: auto;
        gap: $space;

        @media #{$report-tablet} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media #{$report-mobile} {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &-card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        aspect-ratio: 1 / 1;

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: -1;
        }
    }

    &-link {
        position: relative;
        display: block;
        font-size: 1.5rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $white;
        padding: $gap $gap-md;
        background-color: $green-2021;

        &:hover {
            color: $white;

            &::after {
                right: $gap;
            }
        }

        &::after {
            position: absolute;
            top: 50%;
            right: $gap-md;
            content: '';
            display: block;
            width: 1.75rem;
            height: 1rem;
            background-image: url(../images/report-arrow-link-white.png);
            background-size: contain;
            background-repeat: no-repeat;
            transform: translateY(-50%);
            transition: right $transition;
        }
    }
}
