﻿/// <reference path="report.scss">
%section {
    margin-top: $report-section-gap;

    @media #{$report-tablet} {
        margin-top: $report-section-gap-tablet;
    }

    @media #{$report-mobile} {
        margin-top: $report-section-gap-mobile;
    }

    & + .report-two-link-download {
        margin-top: 0;
    }
}

%h1 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.2;

    @media #{$report-tablet} {
        font-size: 2.75rem;
    }

    @media #{$report-mobile} {
        font-size: 2.5rem;
    }
}

%h2 {
    font-size: 2.75rem;
    font-weight: 500;
    line-height: 1.2;

    @media #{$report-tablet} {
        font-size: 2.5rem;
    }

    @media #{$report-mobile} {
        font-size: 2.25rem;
    }
}

%h3 {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.2;
}

%font-sm {
    font-size: .875rem;
    line-height: 1.4;
}

%font-reg {
    font-size: 1rem;
    line-height: 1.4;
}

%font-lg {
    font-size: 1.125rem;
    line-height: 1.4;
}

%font-xl {
    font-size: 1.25rem;
    line-height: 1.4;
}

%font-xxl {
    font-size: 1.375rem;
    line-height: 1.4;

    @media #{$report-mobile} {
        font-size: 1.25rem;
    }
}

%font-xxxl {
    font-size: 1.55rem;
    line-height: 1.4;

    @media #{$report-mobile} {
        font-size: 1.375rem;
    }
}

%font-subhead {
    font-size: 1.875rem;
    line-height: 1.4;

    @media #{$report-mobile} {
        font-size: 1.75rem;
    }
}

%weight-bold {
    font-weight: $font-weight-bold;
}

%weight-black {
    font-weight: $font-weight-black;
}

%font-green {
    color: $green-2021;
}

%report-btn {
    font-size: .875rem;
    font-weight: $font-weight-black;
    text-transform: uppercase;
    color: $white;
    padding: $gap-sm $gap-lg;
    background-color: $green-2021;
    border: 1px solid $green-2021;
    border-radius: $gap-sm;
    appearance: none;

    &:hover {
        text-decoration: none;
        color: $green-2021;
        background-color: $white;
        cursor: pointer;
        border-color: $green-2021;
    }
}

%report-btn-outline {
    display: inline-block;
    font-size: .875rem;
    font-weight: $font-weight-black;
    text-transform: uppercase;
    color: $green-2021;
    padding: $space $gap;
    background-color: $white;
    border: 1px solid $green-2021;
    border-radius: $gap-sm;
    transition: background-color $transition;
    appearance: none;

    &:hover {
        text-decoration: none;
        color: $white;
        background-color: $green-2021;
        cursor: pointer;
    }
}

%logo-bullets {
    list-style-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='1.1em' height='1.1em' viewBox='0 0 55.7 48.1' style='enable-background:new 0 0 55.7 48.1;' xml:space='preserve'%3E%3Cpath fill='#{'%23' + str-slice(#{$report-workforce}, 2, -1)}' d='M23.5,25.2l-2.3-14c-0.3-1.6,1.3-2.9,2.7-2.4l12.8,4.5c1.4,0.5,1.8,2.5,0.6,3.5l-10.5,9.6 C25.6,27.4,23.8,26.8,23.5,25.2z M20.2,2.3l25.1,9.6c0.5,0.2,1,0.1,1.3-0.3L55.4,2c0.7-0.8,0.2-2-0.9-2H20.7 C19.3,0,19,1.8,20.2,2.3z M19.3,30.3L15,3.8c-0.1-0.5-0.4-0.9-0.9-1L1.4,0c-1-0.2-1.8,0.9-1.3,1.8l16.9,29.3 C17.7,32.2,19.5,31.6,19.3,30.3z M45.9,16.6l-20.9,17c-0.4,0.3-0.5,0.8-0.4,1.3l3.9,12.3c0.3,1,1.6,1.1,2.2,0.2l16.9-29.3 C48.4,17,47,15.8,45.9,16.6z'/%3E%3C/svg%3E");
}