﻿/// <reference path="../../cfi-main.scss">

.facts {
    h4 {
        @include font-roboto-medium;
        margin-bottom:0; 
    }

    .stat {
        color: $blue;
        font-size: 24px;
        margin: 8px 0 12px;

        @media #{$media-desktop-view} {
            font-size: 42px;
        }
    }
}