﻿/// <reference path="../../cfi-main.scss">

.download-list,
.cf-content ul.download-list {
    @include remove-list-format;
    @include clearfix;
    @include color-links($dark-gray, $blue);

    @media #{$media-desktop-view} {
        @include flex-end;
        align-items: flex-start;
        flex-wrap: wrap;
    }

    > li {
        @include make-md-column(6);
        @include remove-default-col-padding;
        display: block;
        margin-top: $v-spacing-sm;
        padding-left: 30px;
        position: relative;

        a {
            &:before {
                @include budicon;
                content: $bi-file;
                font-size: 20px;
                left: 0;
                position: absolute;
                top: 2px;
            }
        }
    }
}
