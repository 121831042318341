﻿/// <reference path="../../cfi-main.scss">

.locations-landing-results {
    .no-locations-message {

        h3 {
            color: $blue;
            margin: 15px 0;
        }

        @media #{$media-desktop-view} {
            min-height: 500px;
        }
    }

    ul {
        @include remove-list-format;
        @include make-row;

        li {
            margin-bottom: $grid-gutter-width-double;
        }
    }

    .content {
        @include gray-border;
        position: relative;



        .medium {
            @include font-roboto-medium;
        }

        h3 {
            color: $blue;

            @media #{$media-mobile-view} {
                font-size: 24px;
                line-height: 30px;
            }
        }

        .location-type,
        .location-type-empty {
            line-height:1.4em;

            @media #{$media-desktop-view} {
                height: $v-spacing-lg;
                margin-bottom: $v-spacing-sm;
            }
        }

        .location-type {
            background: $lightest-gray;
            padding: 10px $grid-gutter-width; 

            @media #{$media-desktop-view} {
                @include flex-v-align-justify;
            }
        }

        .result-wrapper {
            padding: $grid-gutter-width;

            @media #{$media-desktop-view} {
                padding-top: 0;
                padding-bottom: $v-spacing-lg;
            }

            > div {
                @include color-links($dark-gray, $blue);
                margin-bottom: $v-spacing-xs;

                &.address,
                &.products-list {
                    span {
                        display: block;
                    }
                }

                &.open-closed {
                    color: $blue;

                    span {
                        text-transform: uppercase;
                    }
                }

                &.products-list {

                    @media #{$media-mobile-view} {
                        margin-top: $v-spacing-sm;
                    }

                    .products {

                        @media #{$media-mobile-view} {
                            > div {
                                padding: $button-dropdown-font-padding;
                            }
                        }

                        @media #{$media-desktop-view} {
                            display: block;
                            height: auto !important; //incase its collapsed on mobile then resize the screen to desktop
                        }

                        span {
                            @include hidden-mobile;
                        }
                    }

                    .toggle {
                        @include btn-border-toggle(block);

                        @media #{$media-desktop-view} {
                            display: none;

                            &.collapsed {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .arrow-link {
            margin-top: $v-spacing-sm;

            @media #{$media-desktop-view} {
                bottom: $v-spacing-sm;
                left: $grid-gutter-width;
                margin: 0;
                position: absolute;
            }
        }
    }
}
