﻿/// <reference path="../cfi-main.scss">

.leadership-bio {

    &-content {
        display: flex;
        justify-content: space-between;

        @media #{$media-mobile-xs} {
            display: block;
        }
    }

    &-image {
        flex: 1;
        margin: 0 1.875rem 0 0;

        @media #{$media-mobile-xs} {
            margin-bottom: 1.5rem;
        }

        .bio-image {
            display: block;
            width: 100%;
            max-width: 260px;
        }
    }

    &-copy {
        flex: 2;

        .bio-title {
            font-size: .875rem;
            font-weight: $font-weight-black;
            margin: 0 0 1.75rem 0;

            @media #{$media-mobile-xs} {
                font-size: .75rem;
            }

            &::after {
                content: '';
                display: block;
                width: 1.5rem;
                height: 1px;
                margin: .75rem 0 0 0;
                background-color: $black;
            }
        }

        .bio-name {
            font-size: 3.25rem;
            font-weight: $font-weight-light;
            color: $green;
            margin: 0 0 1.875rem 0;
            line-height: 1;
            @media #{$media-mobile-xs} {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
        }
    }
}


.report .leadership-bio-copy .bio-name {
    @media #{$media-mobile-xs-min} {
        font-size: 2.75rem;
    }
}