@font-face {
  font-family: 'budicon';
  src: url('/Static/CFI/libs/budicon/budicon.eot?42886572');
  src: url('/Static/CFI/libs/budicon/budicon.eot?42886572#iefix') format('embedded-opentype'),
       url('/Static/CFI/libs/budicon/budicon.woff?42886572') format('woff'),
       url('/Static/CFI/libs/budicon/budicon.ttf?42886572') format('truetype'),
       url('/Static/CFI/libs/budicon/budicon.svg?42886572#budicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin budicon {
    font-family: "budicon";
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    line-height: 1;
    position:relative;
    text-decoration: inherit;     
    text-transform: none;
    top:1px;
}


$bi-var-arrow: "\eb4b";
$bi-var-arrow-up: "\eb4f"; 
$bi-var-search: "\eb19";
$bi-var-web-download: "\ea3c";
$bi-var-out-link: "\eb06";
$bi-var-caret-down: "\eac5";
$bi-var-caret-left: "\eafa";
$bi-var-caret-right: "\eb14";
$bi-var-menu: "\eaf1";
$bi-var-close: "\eae3";
$bi-circle-plus: "\ead9";
$bi-circle-minus: "\ead8"; 
$bi-direction: "\eae7";
$bi-check: "\eb23";
$bi-file: "\e86a";
$bi-plus: "\eb0a";
$bi-circle-close: "\ead7"; 