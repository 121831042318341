﻿@import "../libs/bootstrap/stylesheets/bootstrap/variables";
@import "../libs/budicon/budicon";

// Added colors in new palette
$green-2021: #015846;
$bright-green-2021: #54CE32;
$light-bright-green-2021: lighten($bright-green-2021, 46%);
$bright-blue-2021: #3FA9F5;
$teal-2021: #00C7B1;

// Colors
$black: #000;
$shadow-gray: #666;
$dark-gray: #4a4a4a;
$gray: #8e8e93;
$shadow-gray: #666666;
$light-gray: #d7d7d7;
$lightest-gray: #f2f2f2;
$white: #fff;
$green: $bright-green-2021;
$blue: $green-2021;
$light-blue: $bright-blue-2021;
$red: red;
$video-background-color: #000;

// Fonts
$font-family-roboto: "Montserrat", sans-serif;
$font-family-roboto-condensed: "Montserrat", sans-serif;
$font-weight-black: 700;
$font-weight-semibold: 600;
$font-weight-bold: 500;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;
$font-weight-thin: 300;
$base-font-size: 1rem;

// Media Queries
$media-mobile-view: "screen and (max-width: #{$screen-sm-max})";
$media-desktop-view: "screen and (min-width: #{$screen-md-min})";
$media-mobile: "screen and (max-width: 600px)";
$media-mobile-xs: "screen and (max-width: 480px)";
$media-mobile-xs-min: "screen and (min-width: 480px)";
$media-xs: "screen and (max-width: #{$screen-xs-max})";
$media-sm-max: "screen and (max-width: #{$screen-sm-max})";
$media-sm-min: "screen and (min-width: #{$screen-sm-min})";
$media-md-max: "screen and (max-width: #{$screen-md-max})";
$media-md-min: "screen and (min-width: #{$screen-md-min})";
$media-lg-min: "screen and (min-width: #{$screen-lg-min})";

// Navigation heights
$nav-top-bar-height: 40px;
$nav-bottom-bar-height: 80px;
$nav-total-height: 120px;
$nav-fixed-height: 60px;
$mobile-header-height: 50px;

// Spacing
$v-spacing-xs: 15px;
$v-spacing-sm: 30px;
$v-spacing-lg: 60px;
$grid-gutter-width-half: ($grid-gutter-width / 2);
$grid-gutter-width-double: ($grid-gutter-width * 2);

$sliver: 0.25rem;
$space: 0.5rem;
$gap-sm: 1rem;
$gap: 1.5rem;
$gap-md: 2.5rem;
$gap-lg: 3rem;
$gap-xl: 4rem;
$gap-xxl: 6rem;

// Buttons, Dropdown Sizes
$button-dropdown-font-size: 15px;
$button-dropdown-font-padding: 13px;
$ui-icon-size: 1.25rem;

// Other constants
$container-narrow: 56rem;
$max-text-width: 70ch;
$large-max-width: 1440px;
$transition: .5s;
$slower-transition: .66s;
$container-box-shadow: 0 0 20px 0 rgba($shadow-gray,0.25);
