﻿.report-goal-carousel {
    .carousel-page::after {
        border: 3px solid $report-gray;
    }

    .carousel-pagination-nums {
        font-weight: $font-weight-bold;
        color: $green-2021;
        padding: 0;
    }

    .carousel-arrow {
        padding: $space $gap;
        border-radius: $sliver;

        & + .carousel-arrow {
            margin-left: $space;
        }
    }

    &-head {
        text-align: center;
        color: $green-2021;
        padding: $gap-lg 0;
    }

    &-slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border: 1px solid $report-gray;
        opacity: .6;

        &.is-active {
            opacity: 1;
            transition: opacity $transition;
        }

        @each $theme, $color in $report-themes {
            &.theme-#{$theme} {
                background-color: rgba($color, .05);

                .report-goal-carousel-tag,
                .report-goal-carousel-year,
                .report-goal-carousel-progress {
                    color: $color;
                }

                .report-goal-carousel-title {
                    color: $color;
                }
            }
        }
    }

    &-track {
        margin-bottom: $gap-lg;
    }

    &-tag {
        padding: $gap;
        background-color: $white;
    }

    &-title {
        font-weight: $font-weight-black;
        padding: $gap;
    }

    &-year {
        font-weight: $font-weight-black;
    }

    &-update {
        display: flex;
        justify-content: space-between;
        gap: $gap;
        padding: $gap $gap $gap-sm $gap;
        background-color: $white;
    }

    &-tag {
        font-weight: $font-weight-bold;
    }

    &-progress {
        font-weight: $font-weight-bold;
        font-style: italic;

        &.achieved {

            &::after {
                content: '\02714';
                margin-left: $space;
            }
        }
    }

    &-desc {
        padding: 0 $gap $gap $gap;
        background-color: $white;

        p:nth-child(n) {
            @extend %font-lg;
            font-weight: $font-weight-bold;
        }
    }
}
