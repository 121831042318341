﻿/// <reference path="../cfi-main">
.report,
.refresh {

    section {
        @extend %section;
    }
    // typography
    h1, .h1-style, h1 > p {
        @extend %h1;
    }

    h2, .h2-style, h2 > p {
        @extend %h2;
    }

    h3, .h3-style, h3 > p {
        @extend %h3;
    }

    .font-sm {
        @extend %font-sm;
    }

    .font-lg {
        @extend %font-lg;
    }

    .font-xl {
        @extend %font-xl;
    }

    .font-xxl {
        @extend %font-xxl;
    }

    .font-xxxl {
        @extend %font-xxxl;
    }

    .weight-bold {
        @extend %weight-bold;
    }

    .weight-black {
        @extend %weight-black;
    }

    .font-green {
        @extend %font-green;
    }

    &-btn {
        @extend %report-btn;
    }

    &-btn-secondary {
        @extend %report-btn;
    }

    &-btn-outline {
        @extend %report-btn-outline;
    }
    // container
    .container {
        &::before {
            display: none;
        }
    }

    .container-narrow {
        @extend %container-narrow;
    }
}

.report {
    .container {
        width: 100%;
        max-width: $report-container-narrow + ($report-desktop-margin * 2);

        @media #{$media-desktop-view} {
            padding-left: $report-desktop-margin;
            padding-right: $report-desktop-margin;
        }

        @media #{$media-md-max} {
            padding-left: $report-medium-margin;
            padding-right: $report-medium-margin;
        }

        @media #{$media-sm-max} {
            padding-left: $report-small-margin;
            padding-right: $report-small-margin;
        }
    }
}


@each $theme, $color in $report-themes {
    .theme-#{$theme} {
        .report-btn {
            background-color: $color;
            border-color: $color;

            &:hover {
                background-color: $white;
                color: $color;
            }

            &:focus {
                background-color: darken($color, 6%);
                color: $white;
            }
        }

        .report-btn-outline {
            border-color: $color;
            background-color: $white;
            color: $color;

            &:hover,
            &:focus {
                background-color: $color;
                color: $white;
            }

            &:focus {
                background-color: darken($color, 6%);
            }
        }
    }
}

// Generic

.report-block-head {
    text-align: center;

    & > * + * {
        margin-top: $report-heading-margin;
    }
}
