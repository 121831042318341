﻿/// <reference path="../../cfi-main.scss">

/* three column layout */
.three-column-layout {
    @include make-row;

    > .column {
        @include make-md-column(4);
    }
    /* flex layout - equal heights columns (sm and up) */
    &.flex {
        @media #{$media-desktop-view} {
            @include column-flex;
        }
    }
}

/* two equal columns */
.two-column-layout {
    @include make-row;

    > .column {
        @include make-md-column(6);

        &.left-offset {
            @include make-md-column-offset(6);
        }
    }

    &.full-bleed {
        margin-left: 0;
        margin-right: 0;

        > .column {
            @include remove-default-col-padding;
        }
    }
    /* flex layout - equal heights columns (sm and up) */
    &.flex {
        @media #{$media-desktop-view} {
            @include column-flex;
        }
    }
}


/* two column 3/4, 1/4 */
.two-column-right-rail-layout {
    @include make-row;

    .left-column {
        @include make-md-column(8);
    }

    .right-column {
        @include make-md-column(4);
    }
}
