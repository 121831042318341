﻿/// <reference path="report">

.report-secondary-nav {
    background-color: mix($report-workforce, $white, 10%);
    position: sticky;
    top: 60px;
    z-index: 2;

    @media #{$media-sm-max} {
        display: none;
    }

    &-list {
        width: 100%;
        max-width: $large-max-width;
        margin: auto;
        padding-inline: $report-small-margin;
        list-style-type: none;
        display: flex;
        justify-content: center;
        gap: 5vw;
    }

    &-item {
        a {
            display: block;
            padding-block: $gap;
            color: $report-dark-gray;
            position: relative;
            // unique typography styles
            font-size: 0.75rem;
            text-transform: uppercase;
            line-height: 1.25;

            &:hover,
            &:focus {
                color: $report-workforce;

                &::after {
                    background-color: $report-workforce;
                    opacity: 1;
                }
            }

            &::after {
                content: '';
                background-color: $report-safety;
                height: 6px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                transition: 0.3s;
                opacity: 0;
            }

            &[aria-current="page"] {
                color: $report-workforce;
                font-weight: $font-weight-semibold;

                &::after {
                    opacity: 1;
                }
            }
        }
    }
}
