﻿$report-four-panel-media-text-max-width: 35rem;

.report-four-panel-media {
    padding: $gap-xl 0 $gap-md 0;
    background-color: rgba($green-2021, .05);

    @media #{$report-tablet} {
        padding: $gap-md 0 $gap-sm 0;
    }

    @media #{$report-mobile} {
        padding: $gap-sm 0 0 0;
    }

    &-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        gap: $gap;

        @media #{$report-mobile} {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            grid-template-rows: auto;
        }
    }

    &-item {
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media #{$report-mobile} {
                position: relative;
                height: auto;
                object-fit: unset;
            }
        }

        .adage-video-native,
        .adage-video-iframe {
            display: flex;
        }
    }

    &-title {
        margin-bottom: $gap;
    }

    &-text {
        max-width: $report-four-panel-media-text-max-width;
        padding-bottom: $gap-xl;

        @media #{$report-mobile} {
            padding-bottom: 0;
        }
    }

    &-tag {
        position: absolute;
        top: -$gap-md;
        left: 0;
        color: $report-bright-green;

        @media #{$report-mobile} {
            position: relative;
            top: unset;
            margin-bottom: $gap-sm;
        }
    }
}
