﻿$report-attributed-quote-copy-width: 64rem;
$report-attributed-quote-image-width: 125px;
$report-attributed-quote-image-height: 135px;
$report-attributed-quote-image-radius: 22px;

.report-attributed-quote {
    padding: $gap-xxl 0;
    background-color: rgba($report-safety, .1);

    @media #{$report-mobile} {
        padding: $gap-lg 0;
    }

    &-copy {
        text-align: center;
        margin-bottom: $gap-lg;

        p {
            @extend %font-xxxl;
            @extend %weight-bold;
            max-width: $report-attributed-quote-copy-width;
            color: $report-dark-gray;
            margin: 0 auto;
        }
    }

    &-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $gap-md;

        @media #{$report-mobile} {
            flex-direction: column;
            gap: $gap-sm;
            text-align: center;
        }
    }

    &-image {
        width: $report-attributed-quote-image-width;
        height: $report-attributed-quote-image-height;
        border-radius: $report-attributed-quote-image-radius;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-name {
        @extend %font-xxxl;
        @extend %weight-black;
        color: $green-2021;
    }

    &-title {
        @extend %font-xxl;
        @extend %weight-bold;
        color: $report-dark-gray;
    }
}
