﻿/// <reference path="../../cfi-main.scss">

.footer-social-links {
    > ul {
        @include remove-list-format;

        @media #{$media-desktop-view} {
            text-align: right;
        }

        > li {
            display: inline-block;
            vertical-align: middle;

            @media #{$media-mobile-view} {
                margin-right: $grid-gutter-width;
            }

            @media #{$media-desktop-view} {
                margin-left: $grid-gutter-width;
            }

            a {
                color: $gray;
                display: block;
                font-size: 28px;

                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
