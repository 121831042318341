﻿$report-leadership-image-width: 300px;
$report-leadership-image-width-narrow: 200px;
$report-leadership-image-width-wide: 450px;
$report-leadership-image-padding-wide: 225px;

.report-leadership-msg {
    &:nth-child(n) {
        margin-top: $report-leadership-image-padding-wide;
    }

    &-head {
        position: relative;
        color: white;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: $green-2021;
            z-index: -1;
        }

        &-container {
            position: relative;
            display: flex;
        }

        &-title {
            flex: 0 0 calc(100% - #{$report-leadership-image-padding-wide});
            color: $bright-green-2021;
            padding: $gap-md 0;

            @media #{$report-tablet} {
                flex: 0 0 calc(100% - #{$report-leadership-image-width});
            }

            @media #{$report-mobile} {
                flex: 0 0 calc(100% - #{$report-leadership-image-width-narrow});
            }

            .leader {
                font-weight: $font-weight-black;
            }
        }

        &-image {
            position: absolute;
            top: 50%;
            right: -$report-leadership-image-padding-wide;
            width: $report-leadership-image-width-wide;
            aspect-ratio: 1 / 1;
            transform: translateY(-50%);

            @media #{$report-tablet} {
                right: 0;
                width: $report-leadership-image-width;
            }

            @media #{$report-mobile} {
                width: $report-leadership-image-width-narrow;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                mask-image: url(../images/triangle-mask-right.svg);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
            }
        }
    }

    &-main {
        padding: $gap-xl 0;
        background-color: rgba($green-2021, .05);
    }


    &-greeting {
        font-weight: $font-weight-bold;
        color: $green-2021;
        margin-bottom: $gap;
    }

    &-body {

        p, li {
            @extend %font-lg;
            font-weight: $font-weight-normal;
            margin-top: $gap;
        }
    }

    &-link {
        margin-top: $gap-md;
        text-align: center;
    }
}
