﻿/// <reference path="../cfi-main.scss">
$report-hero-height: 22rem;
$report-hero-title-padding: 2rem;

.report {

    &-hero {
        position: relative;
        height: $report-hero-height;

        @each $theme, $color in $report-themes {
            &.theme-#{$theme} {
                background-color: $color;

                .report-hero-overlay::after {
                    background-color: $color;
                }
            }
        }

        &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        &-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                opacity: .9;
            }
        }

        &-title {
            position: relative;
            text-align: center;
            color: $white;
            padding: $report-hero-title-padding 0;
            z-index: 1;
        }
    }
}
