﻿.report-two-column-link-list {
    text-align: center;

    h2 {
        margin-bottom: $gap-md;

        @media #{$report-tablet} {
            margin-bottom: $gap;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        gap: $gap;

        @media #{$report-mobile} {
            flex-direction: column;
            gap: 0;
        }
    }

    &-links {
        flex: 1;
        list-style: none;
        margin: 0;
        padding: 0;

        a {
            display: block;
            text-decoration: underline;
            text-decoration-color: inherit;
            color: $green-2021;
            padding: $space 0;
            transition: text-decoration-color $transition;

            &:hover {
                text-decoration-color: transparent;
            }
        }
    }
}
