﻿/// <reference path="cfi-main.scss">
@import "../libs/bootstrap/stylesheets/bootstrap/mixins";
@import "../libs/bootstrap/stylesheets/bootstrap/_normalize.scss";
@import "../libs/font-awesome/scss/_variables.scss";

// Fonts
@mixin font-roboto() {
    font-family: $font-family-roboto;
    font-weight: $font-weight-normal;
    -webkit-font-smoothing: antialiased;
}

@mixin font-roboto-bold() {
    @include font-roboto;
    font-weight: $font-weight-bold;
}

@mixin font-roboto-medium() {
    @include font-roboto;
    font-weight: $font-weight-medium;
}

@mixin font-roboto-light() {
    @include font-roboto;
    @include letter-spacing-25;
    font-weight: $font-weight-light;
}

@mixin font-roboto-thin() {
    @include font-roboto;
    @include letter-spacing-25;
    font-weight: $font-weight-thin;
}

@mixin font-roboto-condensed() {
    font-family: $font-family-roboto-condensed;
    font-weight: $font-weight-normal;
    -webkit-font-smoothing: antialiased;
}

@mixin font-roboto-condensed-bold() {
    @include font-roboto-condensed;
    font-weight: $font-weight-bold;
}

@mixin font-roboto-condensed-light() {
    @include font-roboto-condensed;
    font-weight: $font-weight-light;
}

@mixin fa() {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    transform: translate(0, 0);
}

// Typography

@mixin h1 {
    @include font-roboto-medium;
    font-size: 31px;
    letter-spacing: 1px;
    line-height: 1.2em;

    @media #{$media-desktop-view} {
        font-size: 36px;
    }

    @media #{$media-desktop-view} {
        font-size: 42px;
    }
}

@mixin h2 {
    @include font-roboto-light;
    font-size: 16px;
    line-height: 1.4em;

    @media #{$media-desktop-view} {
        font-size: 20px;
    }

    @media #{$media-desktop-view} {
        font-size: 24px;
    }
}

@mixin h3 {
    @include font-roboto-medium;
    font-size: 18px;
    line-height: 1.2em;

    @media #{$media-desktop-view} {
        font-size: 21px;
    }
}

@mixin h4 {
    @include font-roboto;
    font-size: 13px;
    margin: 0;
    text-transform: uppercase;

    @media #{$media-desktop-view} {
        font-size: 14px;
    }
}

@mixin h5 {
    @include font-roboto;
    font-size: 13px;
    line-height: 1.7;
    margin: 0;
}


@mixin h6 {
    @include font-roboto-medium;
    font-size: 15px;
    margin: 0;
}

@mixin p {
    @include font-roboto-light;
    font-size: $base-font-size;
}

@mixin p-small {
    font-size: $base-font-size * 0.875;
    line-height: 1.4;
}

@mixin font-xxl {
    @include font-roboto-medium;
    font-size: 38px;
    letter-spacing: 1px;
    line-height: 1.2em;

    @media #{$media-desktop-view} {
        font-size: 42px;
    }

    @media #{$media-desktop-view} {
        font-size: 48px;
    }
}

@mixin font-xl {
    @include font-roboto-medium;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 1.2em;

    @media #{$media-desktop-view} {
        font-size: 30px;
    }

    @media #{$media-desktop-view} {
        font-size: 38px;
    }
}

@mixin font-lg {
    font-size: 20px;
    line-height: 1.35em;
}

// Global Mixins
@mixin remove-list-format() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin flex-v-align() {
    display: flex;
    align-items: center;
}

@mixin flex-v-align-justify() {
    @include flex-v-align;
    justify-content: space-between;
}

@mixin flex-end() {
    display: flex;
    align-items: flex-end;
}

@mixin column-flex() {
    display: flex;
    flex-wrap: wrap;

    .column {
        display: flex;

        .content {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
    }
}

@mixin rounded-corners($radius) {
    border-radius: $radius;
}

@mixin fade-in($fade-duration) {
    transition: opacity ease-in-out $fade-duration, visibility linear $fade-duration;
    opacity: 1;
    visibility: visible;
}

@mixin fade-out($fade-duration) {
    transition: opacity ease-in-out $fade-duration, visibility linear $fade-duration;
    opacity: 0;
    visibility: hidden;
}

@mixin transition($transition, $property, $duration) {
    -moz-transition: $transition $property $duration;
    -o-transition: $transition $property $duration;
    -webkit-transition: $transition $property $duration;
    transition: $transition $property $duration;
}

@mixin transition-all-default() {
    @include transition(all, ease, 0.4s);
}

@mixin letter-spacing($photoshop-value, $font-size) {
    letter-spacing: ($photoshop-value * $font-size / 1000) + 0px;
}

@mixin letter-spacing-25 {
    @include letter-spacing(25, 18px);
}

@mixin background-image-cover($position-x, $position-y) {
    background-position: $position-x $position-y;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin transparent-black-bg($opacity) {
    background: rgba(0,0,0, $opacity);
}

@mixin banner-overlay() {
    @include transparent-black-bg(.2);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@mixin right-rail-styling {
    background: $lightest-gray;
    padding: $grid-gutter-width;
}

@mixin remove-default-col-padding {
    padding-left: 0;
    padding-right: 0;
}

// Visibility
@mixin hidden-mobile() {
    display: none;

    @media #{$media-desktop-view} {
        display: block;
    }
}

@mixin hidden-desktop() {
    display: block;

    @media #{$media-desktop-view} {
        display: none;
    }
}

// Spacing
@mixin v-spacing-top {
    margin-top: $v-spacing-sm;

    @media #{$media-desktop-view} {
        margin-top: $v-spacing-lg;
    }
}

@mixin v-spacing-bottom {
    margin-bottom: $v-spacing-sm;

    @media #{$media-desktop-view} {
        margin-bottom: $v-spacing-lg;
    }
}

@mixin page-margin-bottom {
    @include v-spacing-bottom;
}

@mixin module-padding {
    padding: $v-spacing-lg 0;
}

@mixin general-page-block-spacing {
    margin-bottom: $v-spacing-sm;

    @media #{$media-desktop-view} {
        margin-bottom: $v-spacing-lg;
    }
}

// Borders/Rules

@mixin thick-blue-box-border {
    border: 10px solid rgba(0,86,184, .85);
}

@mixin gray-border {
    border: 1px solid $light-gray;
}

@mixin gray-dark-border {
    border: 1px solid $gray;
}

@mixin gray-border-bottom {
    border-bottom: 1px solid $light-gray;
}

@mixin gray-border-top {
    border-top: 1px solid $light-gray;
}

@mixin gray-dark-border-bottom {
    border-bottom: 1px solid $gray;
}

@mixin gray-dark-border-top {
    border-top: 1px solid $gray;
}

@mixin white-border {
    border: 1px solid $white;
}

@mixin box-with-border($border-color, $text-color) {
    @include transparent-black-bg(.5);
    border: 1px solid $border-color;

    h2 {
        border-bottom: 1px solid $border-color;
        color: $text-color;
        padding: 15px;

        @media #{$media-desktop-view} {
            padding: 12px 30px;
        }
    }

    .content {
        color: $text-color;
        padding: 15px;

        @media #{$media-desktop-view} {
            padding: 30px;
        }

        p {
            color: $text-color;
        }

        .arrow-link {
            display: inline-block;
            margin-top: ($v-spacing-xs + 5);
        }
    }
}




// Buttons / Links
@mixin color-links($color, $hover-color) {
    a, a:active, a:visited {
        color: $color;

        @media #{$media-desktop-view} {
            &:hover {
                color: $hover-color;
            }
        }
    }
}

@mixin arrow-link() {
    @include font-roboto-medium;
    color: $green-2021;
    display: inline-block;
    text-transform: uppercase;

    &:after {
        @include budicon;
        @include font-roboto-medium;
        @include transition(ease, margin-left, 0.4s);
        color: $green-2021;
        content: "\025B8";
        margin-left: 4px;
        top: 1px;
        transform-origin: center center;
        transform: scale(2);
    }

    &:hover {
        color: $bright-blue-2021;
        cursor: pointer;

        &:after {
            color: $bright-blue-2021;
            margin-left: 8px;
        }
    }
}

@mixin arrow-link-black() {
    @include font-roboto-medium;
    color: $black;
    display: inline-block;
    text-transform: uppercase;

    &:after {
        @include budicon;
        @include font-roboto-medium;
        @include transition(ease, margin-left, 0.4s);
        color: $black;
        content: "\025B8";
        margin-left: 4px;
        top: 1px;
        transform-origin: center center;
        transform: scale(2);
    }

    &:hover {
        color: $black;
        cursor: pointer;

        &:after {
            color: $black;
            margin-left: 8px;
        }
    }
}

@mixin arrow-link-white() {
    @include font-roboto-medium;
    color: $white;
    display: inline-block;
    text-transform: uppercase;

    &:after {
        @include budicon;
        @include font-roboto-medium;
        @include transition(ease, margin-left, 0.4s);
        color: $white;
        content: "\025B8";
        margin-left: 4px;
        top: 1px;
        transform-origin: center center;
        transform: scale(2);
    }

    &:hover {
        color: $white;
        cursor: pointer;

        &:after {
            color: $white;
            margin-left: 8px;
        }
    }
}


@mixin arrow-link-green() {
    @include font-roboto-medium;
    color: $green-2021;
    display: inline-block;
    text-transform: uppercase;

    &:after {
        @include budicon;
        @include font-roboto-medium;
        @include transition(ease, margin-left, 0.4s);
        color: $green-2021;
        content: "\025B8";
        margin-left: 4px;
        top: 1px;
        transform-origin: center center;
        transform: scale(2);
    }

    &:hover {
        color: $green-2021;
        cursor: pointer;

        &:after {
            color: $green-2021;
            margin-left: 8px;
        }
    }
}

@mixin btn-prev {
    &:before {
        @include budicon;
        @include transition-all-default();
        content: $bi-var-caret-left;
        color: $light-gray;
        margin-right: 5px;
    }

    &:hover {
        &:before {
            color: $white;
        }
    }
}

@mixin btn-next {
    &:after {
        @include budicon;
        @include transition-all-default();
        content: $bi-var-caret-right;
        color: $light-gray;
        margin-left: 5px;
    }

    &:hover {
        &:after {
            color: $white;
        }
    }
}

@mixin btn-border-expanded($display) {
    @include font-roboto-light;
    background: $blue;
    border: 1px solid $blue;
    color: $white;
    display: $display;
    font-size: $button-dropdown-font-size;
    padding: $button-dropdown-font-padding;
    position: relative;

    &:hover {
        cursor: pointer;
    }
}

@mixin btn-border-collapsed() {
    @include btn-border-expanded(inline-block);
    background: $white;
    border-color: $light-gray;
    color: $dark-gray;

    &:hover {
        background: $blue;
        border-color: $blue;
        color: $white;

        &:after {
            color: $white;
        }
    }

    &:active,
    &:focus {
        background: $white;
        border-color: $light-gray;
        color: $dark-gray;
    }

    &.disabled {
        background: $white;
        border-color: $lightest-gray;
        color: $lightest-gray;

        &:before,
        &:after {
            color: $lightest-gray !important;
        }

        &:hover {
            background: $white;
            border-color: $lightest-gray;
            color: $lightest-gray;
            cursor: default;

            &:before,
            &:after {
                color: $lightest-gray !important;
            }
        }
    }
}

@mixin btn-border-toggle($display: inline-block) {
    @include transition-all-default;

    @if $display == "inline-block" {
        @include btn-border-expanded(inline-block);
    }

    @if $display == "block" {
        @include btn-border-expanded(block);
    }

    &:after {
        @include budicon;
        @include transition-all-default;
        color: $light-gray;
        content: $bi-var-caret-down;
        display: $display;
        margin-left: 10px;
        transform: rotate(180deg);
    }

    &.collapsed {
        /* default state */
        @include btn-border-collapsed;

        @if $display == "block" {
            display: block;
        }

        &:after {
            @include budicon;
            color: $light-gray;
            transform: rotate(0deg);
        }
    }

    @if $display == "block" {

        &:after {
            margin-top: -9px;
            position: absolute;
            right: 13px;
            top: 50%;
        }

        &.collapsed {
            &:after {
                position: absolute;
                margin-top: -7px;
                top: 50%;
            }
        }
    }
}

@mixin blue-dropdown-styles($element: "div") {
    background: $blue;
    color: $white;
    padding-bottom: $v-spacing-lg;

    @if $element == "list" {
        @include remove-list-format;

        li {
            a {
                @include font-roboto-light;
                color: $white;
                border-top: 1px solid $light-blue;
                display: block;
                font-size: $button-dropdown-font-size;
                padding: $button-dropdown-font-padding;

                &:hover {
                    cursor: pointer;
                }

                &.active {
                    color: $light-blue;
                }
            }
        }
    }
}

@mixin nav-link-style {
    @include font-roboto-condensed;
    font-size: 14px;
    letter-spacing: -0.015em;

    @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
        font-size: 12px;
    }

    @media #{$media-mobile-view} {
        display: block;
        padding: 15px;
        font-size: 18px;
    }

    @media #{$media-desktop-view} {
        &:hover {
            text-decoration: underline;

            &:after {
                text-decoration: none;
            }
        }
    }
}

@mixin site-search-icon($color, $hover-color) {
    display: inline-block;
    height: 18px;
    position: relative;
    width: 18px;

    &:after {
        @include budicon;
        color: $color;
        content: $bi-var-search;
        font-size: 18px;
    }

    &:hover {
        cursor: pointer;

        &:after {
            color: $hover-color;
        }
    }
}

@mixin overlay-close {
    height: 25px;
    width: 25px;

    &:after {
        @include budicon;
        color: $green-2021;
        content: $bi-var-close;
        display: inline-block;
        height: 25px;
        font-size: 25px;
        width: 25px;
    }

    &:hover {
        cursor: pointer;
    }
}


@mixin nav-transparency {
    background-color: $white;
}

// Forms
@mixin on-page-search-input {
    background: $white;
    position: relative;

    input {
        background: $white;
        border: none;
        color: $gray;
        display: block;
        outline: none;
        padding: 15px;
        width: 100%;

        &.has-error {
            &[placeholder] {
                color: $red;
            }

            &::-webkit-input-placeholder {
                color: red;
            }

            &:-moz-placeholder {
                color: red;
            }

            &::-moz-placeholder {
                color: red;
            }

            &:-ms-input-placeholder {
                color: red;
            }
        }
    }

    button {
        @include site-search-icon($gray, $blue);
        background: none;
        border: 0;
        margin-top: -9px;
        outline: 0;
        padding: 0;
        position: absolute;
        right: 15px;
        top: 50%;

        &:after {
            top: -1px;
        }
    }

    ::selection {
        background: rgba(0,86,184,0.85);
        color: $white;
    }

    ::-moz-selection {
        background: rgba(0,86,184,0.85);
        color: $white;
    }
}

@mixin styled-checkboxes {

    input[type="checkbox"] {
        display: none;

        + label {
            @include clearfix;
            display: block;

            &:hover {
                cursor: pointer;
            }

            span {
                display: block;
                float: left;

                &.box {
                    background: $white;
                    border: 2px solid $white;
                    cursor: pointer;
                    height: 20px;
                    margin: 2px 10px 0 0;
                    position: absolute;
                    width: 20px;
                }

                &.label-text {
                    margin-left: 30px;
                }
            }
        }

        &:checked {
            + label span {
                &.box {
                    background: $blue;

                    &:after {
                        @include budicon;
                        bottom: 0;
                        color: $white;
                        content: $bi-check;
                        font-size: 10px;
                        height: 10px;
                        left: 0;
                        margin: auto;
                        position: absolute;
                        right: 0;
                        width: 10px;
                    }
                }
            }
        }
    }
}

@function generate-background-icon($color: $black, $type: 'invalid-icon', $color2: '') {
    $_url-color: currentColor;
    $_url-color-2: currentColor;

    @if $color != currentColor {
        @if str-index(#{$color}, '#') {
            $_url-color: '%23' + str-slice(#{$color}, 2, -1);
        }
        @else {
            $_url-color: $color;
        }
    }

    @if $color2 != currentColor {
        $_url-color-2: '%23' + str-slice(#{$color2}, 2, -1);
    }

    @if $type == 'arrow-right' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 13'%3E%3Cpath fill='#{$_url-color}' d='M0.2,6.5c0-0.4,0.3-0.8,0.8-0.8h16l-3.8-3.8c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2l5.7,5.7 l-5.7,5.7c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.8,0-1.1l3.8-3.8h-16C0.5,7.3,0.2,6.9,0.2,6.5z'/%3E%3C/svg%3E%0A");
    }
    @elseif $type == 'arrow-left' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 20 13'%3E%3Cpath fill='#{$_url-color}' d='M19.8,6.5c0,0.4-0.3,0.8-0.8,0.8h-16l3.8,3.8c0.3,0.3,0.3,0.8,0,1.1c-0.1,0.1-0.3,0.2-0.5,0.2S6,12.3,5.8,12.2L0.2,6.5 l5.7-5.7c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L3.1,5.8h16C19.5,5.8,19.8,6.1,19.8,6.5z'/%3E%3C/svg%3E%0A");
    }
}

@mixin ico-arrow($_bgcolor, $direction: left) {
    @if($direction == left) {
        background-image: generate-background-icon($_bgcolor, 'arrow-left');
    }
    @else {
        background-image: generate-background-icon($_bgcolor, 'arrow-right');
    }
}