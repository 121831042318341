﻿/// <reference path="../cfi-main.scss">

.linear {

    &-container {

        .desktop-only {
            display: flex;

            @media #{$media-sm-max} {
                display: none;
            }
        }

        .carousel-hex-pagination {
            margin-top: 2rem;

            @media #{$media-sm-max} {
                margin-top: 1rem;
            }
        }

        .carousel-hex-page {
            display: block;
            width: 20px;
            height: 20px;
            margin: .125rem;
            padding: 0;
            background-color: $white;
            border: 5px solid $green-2021;
            appearance: none;
            opacity: .4;
            transition: opacity .5s ease;

            &[aria-expanded="true"],
            &[aria-current="true"] {
                opacity: 1;
            }
        }
    }

    &-block {
        color: $white;
        padding-top: 6rem;
        padding-bottom: 6rem;
        background-color: $green-2021;

        &::before {
            top: 12rem;
        }

        &::before {
            white-space: nowrap;
            transform: translate(-40%, -110%);
        }
    }

    &-heading {
        font-size: 0.875;
        font-weight: 900;
        letter-spacing: 0.125em;
        text-transform: uppercase;

        &-container {
            max-width: 95rem;
            margin-right: 0;
            margin-bottom: 6rem;
            margin-left: 0;
            padding-left: 6rem;
            padding-right: 6rem;
        }
    }

    &-timeline {
        &-track {
            padding-left: 6rem;
            padding-right: 6rem;

            @media #{$media-sm-max} {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }

        &-item {
            position: relative;
            flex: 1 0 0;
            width: 5.5rem;
            padding: 1.5rem 1rem;
            border-left: 1px solid rgba($white, .1);
            transition: 0.66s ease-in;

            @media #{$media-sm-max} {
                flex: 1;
                padding: 1.5rem 0;
            }

            &.open {
                flex: 1 0 50vw;
                width: 50vw;
                transition: 0.66s ease-out;

                @media #{$media-sm-max} {
                    flex: 1 0 75vw;
                    width: 75vw;
                }

                .linear-timeline-year-btn {
                    width: auto;
                    height: auto;
                }

                .linear-timeline-item-inner, .linear-timeline-item-slider {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &-slider {
                visibility: hidden;
                opacity: 0;
                transition: opacity .66s .33s;

                @media #{$media-sm-max} {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &-inner {
                visibility: hidden;
                opacity: 0;
                transition: opacity .66s .33s;

                @media #{$media-sm-max} {
                    width: calc(75vw - 2rem);
                    visibility: visible;
                    opacity: 1;
                }
            }

            &-expanded {
                width: calc(50vw - 2rem);
                margin-top: 3rem;
                padding: 0 3rem;

                @media #{$media-sm-max} {
                    flex: 0 0 1;
                    width: 100%;
                    margin-top: 1rem;
                    padding: 1rem;
                }
            }
        }

        &-year {

            &-btn {
                font-size: 1rem;
                font-weight: $font-weight-normal;

                &.desktop {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                    background-color: transparent;
                    border: 0;
                    border-radius: 0;
                    appearance: none;
                    transition: background-color .5s ease;
                    z-index: 1;

                    @media #{$media-sm-max} {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;


                    @media #{$media-sm-max} {
                        display: block;
                    }
                }

                &[aria-expanded="false"] {

                    &:hover {
                        background-color: rgba($white, .15);
                    }
                }

                &-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    padding: 1rem;
                    pointer-events: none;
                }
            }
        }

        &-pagination {
            width: 100%;
            margin-top: 2rem;
            padding: 0;

            @media #{$media-sm-max} {
                display: none;
            }
        }
    }

    &-list {
        display: flex;
        width: 100%;
        transition: 0.5s;
    }

    &-slide {
        @media #{$media-sm-max} {
            padding-right: 1rem;
        }

        &-content {
            width: 75%;
            max-width: 50rem;

            @media #{$media-sm-max} {
                width: 100%;
            }

            .header {
                font-size: 3.125rem;
                font-weight: $font-weight-light;
                color: rgba($white, .4);
                margin: 0 0 2rem 0;

                @media #{$media-sm-max} {
                    font-size: 2.75rem;
                    margin: 0 0 .5rem 0;
                }
            }
        }

        &-image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            &-container {
                width: 50%;
                max-width: 50rem;
                margin: 2rem 0 0 0;

                @media #{$media-sm-max} {
                    width: 100%;
                }
            }

            &-picture {
                width: 100%;
                height: 150px;
                overflow: hidden;
            }
        }
    }
}

.timeline {

    &-carousel {

        &-pagination {
            justify-content: flex-start;
            width: 100%;
            margin-top: 1rem;
            padding: 0;
        }
    }
}
