﻿/// <reference path="../cfi-main.scss">

.history-timeline-block {

    .grid {
        @include make-row;
    }

    .history-timeline-tile-block {
        @include make-sm-column(6);
        @include make-md-column(4);
        margin-bottom: $grid-gutter-width;

        > div {
            border: 1px solid $blue;
            position: relative;

            .caption {
                @include flex-v-align;
                border-top: 1px solid $blue;
                color: $blue;
                font-size: 14px;
                height: 75px;
                justify-content: center;
                line-height: 18px;
                overflow: hidden;
                padding: 0 20px;
                text-align: center;

                > div {
                    width:100%; 
                }
            }

            .top {
                background: $white;
                height: 200px;
                padding: 20px;
                position: relative;

                .gray-bg {
                    background: $light-gray;
                    bottom: 0;
                    height: 100px;
                    left: 0;
                    position: absolute;
                    width: 100%;
                }
            }

            .year,
            .icon {
                @include transition(ease, all, 0.2s);
                position: absolute;
                top: 20px;
                z-index: 1;
            }

            .year {
                left: 20px;
            }

            .icon {
                right: 20px;

                &:after {
                    @include budicon;
                    @include transition(ease, all, 0.2s);
                    color: $green;
                    content: $bi-circle-plus;
                    font-size: 30px;
                }
            }

            .section-header h4 {
                margin-bottom: 0;
                padding-bottom: 10px;
            }

            .section-header h4.minigrant {
                color: #ffffff;
                text-transform: capitalize;
            }

            .img-container {
                align-content: center;
                align-items: flex-end;
                display: flex; 
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;

                > div {
                    width:90%;
                } 

                > div.minigrant{
                    width: 100%
                }
            }

            img { 
                @include img-responsive;
                margin: 0 auto 20px;
            }

            img.minigrant {
                margin: 0 auto;
            }
        }
    }

    .description {
        @include transition(ease, all, 0.2s);
        background: $blue;
        color: $white;
        font-size: 14px;
        height: 100%;
        left: 0;
        line-height: 19px;
        opacity: 0;
        padding: 70px 20px 20px;
        position: absolute;
        top: 0;
        visibility: hidden;
        width: 100%;

        @media #{$media-sm-min} {
            padding: 70px 40px 20px;
        }

        @media #{$media-md-min} {
            padding: 70px 20px 20px;
        }

        @media #{$media-lg-min} {
            padding: 70px 40px 20px;
        }
    }

    .history-timeline-tile-block.clickable {

        &.active {
            .description {
                display: block;
                opacity: 1;
                visibility: visible;
            }

            .year {
                h4 {
                    color: $green;

                    &:after {
                        background: $green;
                    }
                }
            }

            .icon {
                &:after {
                    content: $bi-circle-minus;
                    color: $white;
                }
            }
        }
    }
}
