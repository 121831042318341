﻿.well-green {
    color: $white;
    padding: $gap-sm;
    background-color: $green-2021;
}

.well-lightgreen {
    color: $green-2021;
    padding: $gap-sm;
    background-color: $light-bright-green-2021;
}

.well-white {
    color: $green-2021;
    padding: $gap-sm;
    background-color: $white;
}
