﻿/// <reference path="../cfi-main.scss">

.job-search-input {
    @include v-spacing-bottom;

    .job-posting-search-input {
        @include make-sm-column(6);
        @include make-md-column(5);

        @media #{$media-xs} {
            margin-top: $v-spacing-sm;
        }
    }

    .image-column {
        @include make-sm-column(6);
        @include make-md-column-offset(1);
            @media #{$media-desktop-view} {
                float: right !important;
            }
    }

    .dropdown {
        width: 200px;
    }

    .text-input {
        width: 200px;
    }

    .listbox {
        width: 200px;
    }

    .criteria-field {
        margin-bottom: 15px;
    }

    .image {
        @include img-responsive;
    }
}
