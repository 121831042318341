﻿/// <reference path="../../cfi-main.scss">

#location-landing-map {
    @include v-spacing-bottom;
    height: 200px;

    @media #{$media-desktop-view} {
        height: 415px;
    }
}

/* in-map popup */
.google-map-info-window {
    width: 200px;

    @media #{$media-desktop-view} {
        width: 300px;
    }

    p,
    .arrow-link {
        font-size: 12px;
        line-height: 18px;

        span {
            display: block;
        }
    }

    p.type {
        font-weight:500; 
    }

    p.city-state-zip {
        span {
            display:inline-block;
            vertical-align:middle;
        }
    }

    .medium {
        @include font-roboto-medium;
    }

    .location-details {
        margin: $v-spacing-xs 0;

        .location-name {
            color: $blue;
            font-size: 15px;
            margin-bottom: 5px;
        }
    }

    .arrow-link {
        margin-top: 5px;
    }
}
