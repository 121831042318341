﻿/// <reference path="../cfi-main.scss">

.location-page {

    .two-column-right-rail-layout {
        .right-column {
            margin-bottom: $v-spacing-sm;

            @media #{$media-desktop-view} {
                float: right !important;
            }

            > div {
                @include right-rail-styling;

                > section:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    section {
        margin-bottom: $v-spacing-sm;

        @media #{$media-desktop-view} {
            margin-bottom: 40px;
        }

        .content {
            @include make-sm-column(9);

            p, ul {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .facts {
            @include make-sm-column(3);

            @media #{$media-mobile-view} {
                margin-top: $v-spacing-sm;
            }
        }
    }

    h3 {
        color: $blue;
        margin-bottom: 10px;
    }

    .hidden {
        display: none !important;
        visibility: hidden !important;
    }

    .address {
        > div {
            &.map {
                margin-top: $v-spacing-sm;

                a {
                    display: block;
                }

                img {
                    @include img-responsive;
                }
            }
        }
    }

    .products {
        @include color-links($dark-gray, $blue);  
    }

    .transportation-modes {
        ul {
            margin-bottom: 0;
        }
    }

    .notice {
        @include thick-blue-box-border;
        padding: $grid-gutter-width-half;

        @media #{$media-desktop-view} {
            padding: $grid-gutter-width;
        }
    }
}
