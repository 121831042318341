﻿/// <reference path="../cfi-main.scss">

.facts-callout-block {

    @media #{$media-desktop-view} {
        position: relative;
    }

    .two-column-layout {
        @include module-padding;
    }

    .two-column-layout.full-bleed {
        padding: 0;

        @media #{$media-desktop-view} {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .column {
            @include background-image-cover(right, bottom);
            height: 400px;

            @media #{$media-mobile-view} {
                height: 450px;
            }

            @media #{$media-desktop-view} {
                height: 100%;
            }
        }
    }

    .facts {
        @include v-spacing-top;
        margin-bottom: $v-spacing-sm;

        > div {
            @include make-row;

            @media #{$media-md-max} {
                @include column-flex;
                flex-wrap: wrap;
            }

            > div {
                @include make-xs-column(6);
                @include make-sm-column(4);

                @media #{$media-md-max} {
                    margin-bottom: $v-spacing-xs;
                }

                @media #{$media-desktop-view} {
                    padding-right: 30px;
                }
            }
        }
    }

    .additional-links {
        @include v-spacing-bottom;
        margin-top: $v-spacing-lg;

        @media #{$media-xs} {
            @include gray-border-top;
        }

        > .row {
            margin: 0; 

            > div {
                @include make-sm-column(6);
                @include remove-default-col-padding;

                > a {
                    @include arrow-link;
                }
            }

            @media #{$media-xs} {
                > div {
                    > a {
                        @include gray-border-bottom;
                        display: block;
                        padding: 10px 0;
                    }
                }
            }
              
            @media #{$media-sm-min} {
                @include gray-border-bottom;
                padding: 10px 0;

                &:first-child {
                    @include gray-border-top;
                }
            }
        }
    }
}
