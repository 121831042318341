﻿/// <reference path="../cfi-main.scss">

.site-header-block {
    transition: 0.3s all;
    width: 100%;
    padding-top: 0;
    height: $nav-total-height;

    @media #{$media-mobile-view} {
        width: 100%;
        height: $mobile-header-height;
    }

    @media #{$media-desktop-view} {
        padding-top: $nav-top-bar-height;
    }

    &-container {
        background: $white;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
    }

    &.fixed-header {
        height: $nav-fixed-height;

        .top-bar {
            display: none;
        }

        /*.bottom-bar {
            height: $nav-fixed-height;
        }*/
    }

    .top-bar {
        @media #{$media-desktop-view} {
            border-bottom: 1px solid rgba($light-gray, 0.5);
            height: $nav-top-bar-height;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
        }

        .container {
            @media #{$media-mobile-view} {
                @include remove-default-col-padding;
            }
        }
    }

    .bottom-bar {
        .container {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;

            @media #{$media-mobile-view} {
                position: static;
            }
        }

        .main-navigation {
            @include make-md-column(7);

            @media #{$media-mobile-view} {
                @include remove-default-col-padding;
            }
        }

        .site-logo {
            @include make-md-column(2.5);
        }

        .site-search {
            @include make-md-column(2.5);

            @media #{$media-mobile-view} {
                @include remove-default-col-padding;
            }

            @media #{$media-desktop-view} {
                float: right !important;
            }
        }
    }


    &.fixed-header {
        .site-search {
            height: $nav-fixed-height;
        }
    }


    .mobile-nav-open {
        .site-header-block {
            height: 100%;
        }
    }
}
