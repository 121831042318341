﻿/// <reference path="../../cfi-main">
.carousel {
    &-list {
        &,
        &-desktop {
            padding-top: $v-spacing-sm;
            padding-bottom: $v-spacing-sm;
            width: auto;
        }

        &-desktop {
            @extend %container;
            max-width: $large-max-width;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-controls {
        display: flex;
        justify-content: flex-end;

        &-mobile-only {
            @media #{$media-desktop-view} {
                display: none;
            }
        }
    }

    &-slide {
        // this will need to be edited for future carousels
        @media #{$media-mobile-view} {
            margin-top: 0;
        }
    }

    &-arrow {
        flex: 0 0 auto;
        margin-top: $v-spacing-sm;
        margin-bottom: $v-spacing-sm;

        & + & {
            margin-left: $v-spacing-sm;
        }
    }
}

// Fun slidey pagination
.carousel {
    &-pagination {
        @extend %container;
        width: auto;
        display: flex;
        align-items: center;
        max-width: $large-max-width;
        margin-left: auto;
        margin-right: auto;

        & > li {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
        }
    }

    &-page {
        @extend %un-button;
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 2px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            border-top: 2px solid $gray-lighter;
        }

        &:focus {
            box-shadow: 0 0 0 2px $gray-lighter;

            &.is-active {
                box-shadow: none;
            }

            &::before {
                border-color: $gray-light;
            }
        }

        &:active {
            background: transparent;
        }

        &::after {
            border-color: $light-blue;
            transform: translateX(-100%);
            animation: carouselTrackOffToRight 0.3s;
        }

        &.is-active::after {
            transform: translateX(0);
            animation: carouselTrackOnFromLeft 0.3s;
        }
    }

    [data-slide-direction="left"] &-page {
        &::after {
            transform: translateX(100%);
            animation: carouselTrackOffToLeft 0.3s;
        }

        &.is-active::after {
            transform: translateX(0);
            animation: carouselTrackOnFromRight 0.3s;
        }
    }

    &-page-inactive {
        &::after {
            animation: none !important;
        }
    }
}

// First and last index numbers in track bar as seen on some carousels
.carousel-pagination-nums {
    &[data-start-num][data-end-num] {
        &::before,
        &::after {
            color: $blue;
            width: $ui-icon-size;
        }

        &::before {
            content: attr(data-start-num);
            margin-right: $v-spacing-sm;
        }

        &::after {
            content: attr(data-end-num);
            margin-left: $v-spacing-sm;
            text-align: right;
        }

        @media #{$media-mobile-view} {
            &::before,
            &::after {
                content: none;
            }
        }
    }
}

.carousel-controls-nums {
    @extend %container;
    max-width: $large-max-width;
    width: 100%;

    & > *:last-child {
        margin-right: calc(#{$v-spacing-sm} + #{$ui-icon-size});

        @media #{$media-mobile-view} {
            margin-right: 0;
        }
    }
}

.carousel-arrow {
    padding: 10px $v-spacing-xs;
    display: block;
    background: transparent;
    border: 1px solid $blue;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    border-radius: 999em;

    &:hover {
        background-color: rgba($green, 0.5);
    }

    &::after {
        content: '';
        width: $ui-icon-size;
        height: $ui-icon-size * 0.75;
        display: block;
        flex-shrink: 0;
        background-position: center center;
        background-repeat: no-repeat;
    }

    &:focus {
        outline: 2px solid $green;
    }

    &[disabled] {
        border-color: $gray;
        cursor: not-allowed;

        &:hover {
            background: transparent;
        }
    }
}

.btn-arrow {
    &-left {
        &::after {
            @include ico-arrow($blue);
        }

        &[disabled] {
            &::after {
                @include ico-arrow($gray);
            }
        }
    }

    &-right {
        &::after {
            @include ico-arrow($blue, right);
        }

        &[disabled] {
            &::after {
                @include ico-arrow($gray, right);
            }
        }
    }
}

@keyframes carouselTrackOnFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes carouselTrackOffToRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes carouselTrackOnFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes carouselTrackOffToLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}
