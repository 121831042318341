﻿/// <reference path="../report">
.report-initiative-info {
    &-block {
        @include module-padding;
        background-color: rgba($report-safety, 0.15);

        @each $theme, $color in $report-themes {
            &.theme-#{$theme} {
                h2 {
                    color: $color;
                }
            }
        }
    }

    &-list {
        display: flex;
        gap: $gap-md;
        margin-top: $report-heading-margin;

        @media #{$media-mobile} {
            flex-direction: column;
        }
    }

    &-card {
        flex: 1 1 33.3%;

        p {
            font-size: 0.8125rem;

            &.small {
                font-size: 0.725rem;
            }
        }

        & > * + * {
            margin-top: 1.25em;
        }

        img {
            width: 100%;
            height: auto;

            &.triangle {
                position: relative;
                aspect-ratio: 1 / 1;
                object-fit: cover;
                flex: unset;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
                mask-image: url(../images/triangle-mask-left.svg);
                margin-left: 0;
                max-width: 200px;
            }
        }

        &-subhead {
            color: $report-safety;
            font-weight: $font-weight-bold;

            & + * {
                margin-top: 0;
            }
        }

        &-subcontent {
            color: $report-workforce;

            p {
                font-weight: $font-weight-bold;
            }

            & > * + * {
                margin-top: 1em;
            }
        }
    }
}
