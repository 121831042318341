﻿/// <reference path="../cfi-main.scss">

$stats-list-image-dimension: 125px;

.stats-list {
    @include general-page-block-spacing;
    @include module-padding;
    background-color: $lightest-gray;

    &-header {
        max-width: $max-text-width;
        text-align: center;
        margin: 0 auto;
    }

    &-title {
        color: $green-2021;
        margin-bottom: $gap-lg;

        @media #{$media-xs} {
            margin-bottom: $gap;
        }
    }

    &-body {
        > * {
            margin-top: $gap-sm;
        }
    }

    &-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &-item {
        flex: 0 0 33.33%;
        text-align: center;
        @include v-spacing-top;

        @media #{$media-md-max} {
            flex: 0 0 50%;
            margin-top: $gap-md;
        }

        @media #{$media-xs} {
            flex: 0 0 100%;
            margin-top: $gap-lg;
        }
    }

    &-image {
        width: $stats-list-image-dimension;
        height: $stats-list-image-dimension;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto $gap-sm auto;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &-text {
        color: $green-2021;
        padding: $space $gap 0 $gap;

        @media #{$media-xs} {
            padding: $gap-sm 0 0 0;
        }
    }

    &-link {
        text-align: center;
        margin-top: $gap-md;
    }
}
