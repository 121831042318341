﻿/// <reference path="../cfi-main.scss">

.image-comp {
    @include general-page-block-spacing;

    &-container {
        max-width: $max-text-width;
        margin: 0 auto;
    }

    &-head {
        text-align: center;
        color: $green-2021;
    }

    &-body {
        text-align: center;

        > * {
            margin-top: $gap-sm;
        }
    }

    &-item {
        margin-top: $gap-lg;

        img {
            display: block;
            width: 100%;
            margin: $gap-sm 0 $gap-sm 0;
        }
    }
}