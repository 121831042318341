﻿/// <reference path="../cfi-main.scss">

.locations-landing-page {
    .general-page-hero-bg {
        margin-bottom:0;

        @media #{$media-desktop-view} {
            &.has-breadcrumb {
                margin-bottom:0;
            } 
        } 
    }
} 