﻿/// <reference path="../cfi-main.scss">

$project-detail-max-image-height: 350px;

.project-detail {
    @include general-page-block-spacing;

    &-title {
        text-align: center;
        margin-bottom: $gap-lg;

        @media #{$media-xs} {
            margin-bottom: $gap;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: $gap-md;

        @media #{$media-md-max} {
            flex-wrap: wrap;
        }

        & + .project-detail-container {
            margin-top: $gap-lg;
        }
    }

    &-body {
        margin-top: $gap-sm;
        margin-bottom: $gap-lg;
    }

    &-image-align-left {
        .project-detail-text {
            order: 2;

            @media #{$media-md-max} {
                order: 1;
            }
        }

        .project-detail-images {
            order: 1;

            @media #{$media-md-max} {
                order: 2;
            }
        }
    }

    &-text {
        flex: 0 0 45%;

        @media #{$media-md-max} {
            flex: 0 0 100%;
            max-width: $max-text-width;
        }
    }

    &-links {
        list-style: none;
        margin: $gap-sm 0 0 0;
        padding: 0;
    }

    &-link {
        margin-bottom: $gap-sm;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-images {
        flex: 0 0 55%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media #{$media-md-max} {
            flex: 0 0 100%;
        }
    }

    &-image {

        &-1 {
            flex: 0 0 100%;
            aspect-ratio: 2 / 1;

            @media #{$media-md-max} {
                aspect-ratio: 3 / 1;
            }
        }

        &-2 {
            flex: 0 0 100%;
            aspect-ratio: 3 / 1;

            @media #{$media-md-max} {
                aspect-ratio: 4 / 1;
            }
        }

        &-3 {
            flex: 0 0 50%;
            aspect-ratio: 3 / 2;

            &:first-child {
                flex: 0 0 100%;
                aspect-ratio: 3 / 1;
            }
        }

        &-2,
        &-3 {
            max-height: $project-detail-max-image-height;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            padding: $sliver;
        }
    }
}
