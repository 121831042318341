﻿$report-partner-info-image-height: 250px;
$report-partner-info-image-height-tablet: 200px;
$report-partner-info-image-height-mobile: 150px;

.report-partner-info {

    &-head {
        text-align: center;
        color: $green-2021;
        margin-bottom: $gap-lg;
    }

    &-body {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: auto;
        gap: $gap-sm;

        @media #{$report-mobile} {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &-item {
        background-color: rgba($green-2021, .1);
    }

    &-image {
        height: $report-partner-info-image-height;
        padding: $gap-lg $gap-xxl;
        background-color: $white;
        border: 1px solid $report-border;
        border-bottom: 0;

        @media #{$report-tablet} {
            height: $report-partner-info-image-height-tablet;
            padding: $gap-md $gap-xl;
        }

        @media #{$report-mobile} {
            height: $report-partner-info-image-height-mobile;
            padding: $gap $gap-xl;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-desc {
        padding: $gap-lg;

        @media #{$report-tablet} {
            padding: $gap-md;
        }

        @media #{$report-mobile} {
            padding: $gap;
        }

        p:nth-child(n) {
            @extend %weight-bold;
            color: $green-2021;
        }

        a {
            display: block;
            text-transform: uppercase;
            text-decoration: underline;
            text-decoration-color: $green-2021;
            color: $green-2021;
            margin-top: $gap;
            transition: text-decoration-color $transition;

            &:hover {
                color: $green-2021;
                text-decoration-color: transparent;
            }
        }
    }
}
