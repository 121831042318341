﻿/// <reference path="../cfi-main">

// Image & Text Block
.report-image-text-block {
    &-row {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $gap-sm $gap-md;

        @media #{$report-small} {
            grid-template-columns: 1fr;
        }

        & > [class*="column"]:nth-child(n) {
            order: unset;
        }
    }

    .image-column {
        grid-row: 1 / span 2;
        align-self: stretch;
        display: flex;
        align-items: center;

        @media #{$report-small} {
            grid-row: auto;
        }

        .image {
            aspect-ratio: 1 / 1;
        }
    }

    &.text-left {
        .image-column {
            grid-column: 2 / span 1;

            @media #{$report-small} {
                grid-column: auto;
            }
        }
    }

    .text-column {
        align-self: end;
    }

    .cta-column {
        align-self: start;
    }

    .btn-border {
        @extend %arrow-button;
    }
}

// Double Text Block
.report-double-text-card-block-column {
    .report-btn {
        @extend %arrow-button;
    }

    @each $theme, $color in $report-themes {
        &.theme-#{$theme} {
            .report-btn {
                color: $color;
            }
        }
    }
}

// News Preview
.news-preview-block {
    .grid h2 {
        font-weight: $font-weight-light;
    }

    .section-header h2 {
        @extend %h2;
        text-align: center;
        margin-bottom: $gap;
        color: $green-2021;
    }
}

// Stats List
$_homepage-refresh-stat-padding: clamp(#{$gap * 3}, 6vw, #{$gap * 5});

.stats-list-item:nth-child(n) {
    h3 {
        padding-inline: $_homepage-refresh-stat-padding;
        margin: 0;
        margin-top: $gap-sm;
        margin-bottom: $space;

        @each $theme, $color in $report-themes {
            &.theme-#{$theme} {
                color: $color;
            }
        }
    }
}

.stats-list-text {
    padding-inline: $_homepage-refresh-stat-padding;

    p:not([class]) {
        @extend %font-sm;
        font-weight: $font-weight-normal;
    }

    * + * {
        margin-top: 1em;
    }
}
