﻿/// <reference path="../../cfi-main.scss">

.section-header {
    h4,
    .h4-style {
        @include letter-spacing(200, 14);
        color: $dark-gray;
        margin-bottom: $v-spacing-sm;
        padding-bottom: 15px;
        position: relative;

        &:after {
            background: $dark-gray;
            bottom: 0;
            content: " ";
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            width: 25px;
        }
    }

    &.white-text {
        h4,
        .h4-style {
            color: $white;

            &:after {
                background: $white;
            }
        }
    }

    &.block {
        margin-top: $v-spacing-sm;
    }
}