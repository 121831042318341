﻿/// <reference path="../../cfi-main.scss">

.social-share {
    @include remove-list-format;

    > li {
        display: inline-block;
        vertical-align: middle;

        @media #{$media-mobile-view} {
            margin-right: 10px;
        }

        @media #{$media-desktop-view} {
            margin-right: 20px;
        }

        a {
            color: $gray;
            display: block;
            font-size: 28px;

            @media #{$media-desktop-view} {
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}
