﻿/// <reference path="../../cfi-main.scss">

.locations-landing-filters-flyout {
    margin-bottom: 15px;
    position: relative;

    .toggle {
        @media #{$media-mobile-view} {
            @include btn-border-toggle($display: block);
        }

        @media #{$media-desktop-view} {
            @include btn-border-toggle($display: inline-block);
        }
    }

    .collapse,
    .collapsing {
        position: absolute;
        z-index: 10;

        @media #{$media-mobile-view} {
            width: 100%; 
        }

        @media #{$media-desktop-view} {
            width: 33.3333333%;
        }
    }

    .filter-dropdown {
        @include blue-dropdown-styles;
        padding-left: 15px;
        padding-right: 15px;

        h6, p {
            color: $white;
            margin-bottom: 15px;
        }

        section {
            padding-top: ($v-spacing-sm + 15);
        }

        ul {
            @include remove-list-format;
        }

        .product-type {
            @include styled-checkboxes;

            li {
                margin-bottom: 15px;
            }
        }

        .location-search-box {
            @include on-page-search-input;
        }

        .find-location {
            @include btn-border-expanded(block);
            border-color: $white;
            color: $white;
            margin-top: $v-spacing-sm;
            text-align: center;

            &:before {
                @include budicon;
                @include transition-all-default;
                color: $white;
                content: $bi-direction;
                margin-right: 10px;
            }

            &:hover {
                background-color: $white;
                border-color: $white;
                color: $blue;

                &:before {
                    color: $blue;
                }
            }
        }
    }
}
