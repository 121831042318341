﻿/// <reference path="../../cfi-main.scss">

$_start-page-bottom-showing: 6.5rem;

.start-page {
    &-hero {
        min-height: calc(100vh - #{$nav-total-height} - #{$_start-page-bottom-showing});
        color: $green-2021;
        background: $lightest-gray;
        position: relative;
        display: flex;
        overflow: hidden;

        @media #{$media-mobile-view} {
            min-height: calc(100vh - #{$mobile-header-height} - #{$_start-page-bottom-showing});
        }

        &-inner {
            display: flex;
            align-items: flex-end;
            width: 100%;
        }
    }

    &-title {
        font-size: 1.5rem;
        position: relative;
        padding-bottom: $grid-gutter-width-double;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: -50vw;
            right: -50vw;
            width: 200vw;
            height: 200vw;
            background-image: radial-gradient(farthest-corner at 50% 100%, rgba($lightest-gray, 0.9), rgba($lightest-gray, 0) 50%);
        }

        @media #{$media-desktop-view} {
            width: calc(45% + #{$grid-gutter-width-double});
            padding-top: $nav-total-height + $grid-gutter-width;
            padding-bottom: $grid-gutter-width * 1.5;

            &::before {
                content: none;
            }
        }

        & > * {
            position: relative;
        }
    }

    &-heading {
        font-size: 4rem; // TEMP with temp font. 5rem with Degular.
        line-height: 1;
        max-width: 42rem;

        @media #{$media-mobile-view} {
            font-size: 4rem;
        }

        & + * {
            margin-top: 1em;
        }
    }

    &-graphics, &-image, &-vector {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &-image, &-vector {
        background-repeat: no-repeat;
        background-size: cover;
    }

    &-graphics {
        align-self: stretch;
        width: 66%;
        right: -16%;
        left: auto;

        @media #{$media-mobile-view} {
            width: 100%;
            right: -20%;
        }
    }

    &-image {
        top: 0;
        right: 20%;
        background-position: center center;

        @media #{$media-mobile-view} {
            top: $mobile-header-height;
        }
    }

    &-vector {
        background-position: left 20%;
        bottom: -1px;
    }
}
