﻿/// <reference path="../../cfi-main">
$report-image-horiz-container-width: $report-container-narrow + ($report-desktop-margin * 2);
$report-image-vert-container-width: $report-container-narrow + ($report-desktop-margin * 2);
$report-image-vert-text-width: $report-container-narrow;

.report,
.refresh {

    .report-image-text-block {

        &.horizontal {

            .report-image-text-block-container {
                max-width: $report-image-horiz-container-width;
            }

            .report-image-text-block-row {
                justify-content: space-between;
                align-items: center;
                margin: 0;

                @media #{$report-mobile} {
                    flex-direction: column;
                }
            }
        }

        &.vertical {

            .report-image-text-block-container {
                max-width: $report-image-vert-container-width;
            }

            .report-image-text-block-row {
                flex-direction: column;
                padding: 0 $gap-sm;
            }

            .text-column {
                max-width: $report-image-vert-text-width;
                text-align: center;
                margin: 0 auto;
            }
        }

        &.text-top,
        &.text-left {
            .image-column {
                order: 2;

                @media #{$report-mobile} {
                    order: 1;
                }

                &.triangle {
                    mask-image: url(../images/triangle-mask-left.svg);
                }
            }

            .text-column {
                order: 1;

                @media #{$report-mobile} {
                    order: 2;
                }
            }
        }

        &.text-bottom,
        &.text-right {
            .image-column {
                order: 1;

                &.triangle {
                    mask-image: url(../images/triangle-mask-right.svg);
                }
            }

            .text-column {
                order: 2;
            }
        }

        &-container {
            margin: 0 auto;

            &::after {
                display: none;
            }
        }

        &-row {
            display: flex;
            gap: $gap-md;

            &::before,
            &::after {
                display: none;
            }
        }

        .image-column,
        .text-column {
            flex: 1;
            width: unset;
            float: unset;
            margin: 0;
            padding: 0;
        }

        .text-column {
            &.cf-content {
                padding: 0;
            }

            .h3-style:nth-child(n) {
                @extend h2;
                color: $green-2021;
                margin-bottom: $report-heading-margin;
            }

            p {
                margin: $gap-sm 0 0 0;
            }
        }

        .image-column {
            &.triangle {
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
            }

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .image-block-caption {
                margin-top: $gap-sm;
                padding: 0;
                border: 0;
            }
        }
    }
}