﻿// report variables
$report-bright-green: #54CE32;
$report-gray: #707070;
$report-med-gray: #EBEBEB;
$report-dark-gray: #646464;
$report-border: #D8D8D8;
$report-green-gradient: linear-gradient(244deg, rgba(#54CE32,1) 0%, rgba(#0B745E,1) 50%, rgba(#015846,1) 100%) ;

$report-environment: #00C7B1; // teal
$report-safety: #54CE32; // bright green
$report-workforce: #015846; // dark green
$report-community: #3FA9F5; // light blue
$report-governance: #00C7B1; // teal
$report-downloads: #54CE32; // bright green

$report-themes: (
    environment: $report-environment, 
    safety: $report-safety, 
    workforce: $report-workforce, 
    community: $report-community, 
    governance: $report-governance, 
    downloads: $report-downloads
);

$report-small: ( max-width: 640px );
$report-mobile: ( max-width: 767px );
$report-tablet: ( max-width: 992px );
$report-laptop: ( max-width: 1024px );
$report-wide:  ( min-width: 1401px );

$report-container-narrow: 56rem;
$report-section-gap: 4.5rem;
$report-section-gap-tablet: 3.5rem;
$report-section-gap-mobile: 3.5rem;

$report-desktop-margin: 16rem;
$report-medium-margin: 8rem;
$report-small-margin: 3rem;
$report-heading-margin: 3.4375rem;