﻿/// <reference path="../cfi-main.scss">

.logo-grid {
    @include general-page-block-spacing;

    &-head {
        text-align: center;
        margin-bottom: $gap;

        @media #{$media-xs} {
            margin-bottom: $gap-sm;
        }
    }

    &-body {
        max-width: $max-text-width;
        text-align: center;
        margin: 0 auto;

        > * {
            margin-bottom: $gap-sm;
        }
    }

    &-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    &-item {
        flex: 0 0 15%;
        text-align: center;
        padding: $gap;

        @media #{$media-md-max} {
            flex: 0 0 20%;
            padding: $gap;
        }

        @media #{$media-xs} {
            flex: 0 0 33.33%;
            padding: $gap-sm;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }
}