﻿/// <reference path="../cfi-main.scss">

.site-footer-block {
    background: $white;
    padding: $v-spacing-sm 0; 

    img {
        @include img-responsive;
        max-width: 10rem;

        @media #{$media-mobile-view} {
            margin-bottom: $v-spacing-sm;
            max-width: 8rem;
        }
    }

    .row {
        > div {
            @include make-md-column(6);

            @media #{$media-desktop-view} {
                &.right {
                    text-align: right;
                }
            }
        }
    }

    .logo-social {
        @include gray-border-bottom;
        @include gray-border-top;
        margin-bottom: $v-spacing-sm;
        padding: $v-spacing-sm 0;

        @media #{$media-desktop-view} {
            .row {
                @include flex-v-align;
                align-items: flex-end;
            }
        }
    }

    .copyright {
        @include font-roboto;
        color: $gray;
        font-size: 11px;

        .left {
            @media #{$media-mobile-view} {
                margin-bottom: ($v-spacing-sm / 2);
            }
        }
    }
}
