﻿/// <reference path="../cfi-main.scss">

.two-image-block {
    @include general-page-block-spacing;

    .small-column {
        @include make-sm-column(4);
    }

    .large-column {
        @include make-sm-column(8);

        @media #{$media-xs} {
            margin-top: $v-spacing-sm;
        } 
    }

    &.large-left {
        .small-column {
            @media #{$media-sm-min} {
                float: right !important;
            }
        }
    }

    img {
        @include img-responsive;
    }
}

.image-block-caption {
    @include gray-border-bottom;
    margin-top: $v-spacing-xs;
    padding-bottom: $v-spacing-xs;

    @media #{$media-sm-min} {
        margin-top: $v-spacing-sm;
        padding-bottom: $v-spacing-sm;
    }
}
