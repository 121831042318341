﻿/// <reference path="../cfi-main.scss">

$bg-image-min-height: 660px;
$bg-image-min-height-small: 500px;


.content-callout-block {
    /* start layout variations */
    &.bg-image {
        @include module-padding;
        min-height: $bg-image-min-height-small;
        position: relative;

        @media #{$media-lg-min} {
            min-height: $bg-image-min-height;
        }

        .overlay {
            @include banner-overlay;
        }

        .container {
            position: relative;
        }

        .callout-content {
            @include make-sm-column(7);
            @include make-lg-column(5);
        }
        /* content varations for hero backgrounds */
        &.content-right {
            @include background-image-cover(left, center);

            .callout-content {
                @include make-sm-column-offset(5);
                @include make-lg-column-offset(7);
                @include remove-default-col-padding;
            }
        }

        &.content-left {
            @include background-image-cover(right, center);

            .callout-content {
                @include v-spacing-top;
                @include remove-default-col-padding;
            }
        }
    }

    &.framed-text {
        .callout-content {
            @include box-with-border($blue, $white);

            .arrow-link {
                color: $white;

                &:after {
                    color: $white;
                }

                &:hover {
                    color: $bright-blue-2021;

                    &:after {
                        color: $bright-blue-2021;
                    }
                }
            }
        }
    }

    &.large-text {
        &.content-right {
            .callout-content {
                @include make-lg-column-offset(6); //large text needs 6 columns
            }
        }

        .callout-content {
            @include make-lg-column(6); //large text needs 6 columns
            @include color-links($white, $blue);
            color: $white;

            h1, h3, h4, h5, h6, p {
                @include h1;
                color: $white;
                line-height: 1.4em;
            }

            h2 {
                color: $white;
                margin-bottom: $v-spacing-xs;
            }

            p {
                margin: 0;
            }

            .arrow-link {
                margin-top: $v-spacing-xs;
                color: $white;

                &:after {
                    color: $white;
                }

                &:hover {
                    color: $bright-blue-2021;

                    &:after {
                        color: $bright-blue-2021;
                    }
                }
            }
        }
    }

    &.side-by-side {
        position: relative;

        .two-column-layout {

            .column {
                @include module-padding;
                @include make-md-column(6);

                @media #{$media-desktop-view} {
                    height: $bg-image-min-height-small;
                }

                @media #{$media-lg-min} {
                    height: $bg-image-min-height;
                }
            }
        }

        .two-column-layout.full-bleed {
            padding: 0;

            @media #{$media-desktop-view} {
                height: 100%;
                left: 0;
                overflow: hidden;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .column {
                @include background-image-cover(center, center);
                padding: 0;

                @media #{$media-mobile-view} {
                    height: $bg-image-min-height-small;
                }

                @media #{$media-xs} {
                    height: $bg-image-min-height-small - 100;
                }

                @media #{$media-mobile-xs} {
                    height: $bg-image-min-height-small - 200;
                }
            }
        }

        &.content-left {
            .two-column-layout.full-bleed {
                .column {
                    @media #{$media-desktop-view} {
                        margin-left: 50%;
                    }
                }
            }

            .callout-content {
                padding-right: $grid-gutter-width-half;
            }
        }

        &.content-right {
            .container .two-column-layout {
                .column {
                    @media #{$media-desktop-view} {
                        margin-left: 50%;
                        padding-left: $grid-gutter-width;
                    }
                }
            }
        }

        .callout-content {
            @media #{$media-desktop-view} {
                display: flex;
                align-items: flex-end;
                width: 100%;
            }

            @media #{$media-md-min} {
                height: ($bg-image-min-height - 349px);
            }

            @media #{$media-lg-min} {
                height: ($bg-image-min-height - 189px);
            }

            h2 {
                margin-bottom: $v-spacing-xs;
            }

            .arrow-link {
                margin-top: $v-spacing-xs;
            }
        }
    }
    /* rules for html content */
    .html-content {
        p {
            margin-top: $v-spacing-xs;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}
