﻿/// <reference path="../cfi-main.scss">

// this block shares CSS from content-callout-block
.video-callout-block {

    .video {
        background-color: $video-background-color;
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}