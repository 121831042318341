﻿/// <reference path="../../cfi-main.scss">

.toggle-site-view {
    > a {
        &:hover {
            cursor: pointer;
        } 
    }

    @media #{$media-desktop-view} {
        display: none !important;
    }
}
