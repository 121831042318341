﻿/// <reference path="../../cfi-main.scss">

$mobile-header-elements-height: 40px;

.mobile-header {
    @include nav-transparency;
    height: $mobile-header-height;
    width: 100%;

    @media #{$media-desktop-view} {
        display: none;
    }

    .mobile-nav-toggle {
        height: $mobile-header-height;
        width: $mobile-header-height;

        &-container {
            @include flex-v-align-justify;
            @include make-xs-column(4);
        }

        span {
            @include flex-v-align-justify;
            flex-direction: column;
            height: ($mobile-header-elements-height / 2);
            width: 35px;

            &:hover {
                cursor: pointer;
            }

            i {
                background: $blue;
                display: block;
                height: 2px;
                width: 100%;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    .mobile-logo {
        @include make-xs-column(4);
        height: $mobile-header-height;

        img {
            @include img-responsive;
            margin: auto;
            max-height: $mobile-header-elements-height;
        }

        .site-logo .flex-v-align {
            height: $mobile-header-height;
        }
    }
}


/* mobile navigaiton */
body.mobile-nav-open {
    overflow: hidden;
}

.navigation {
    @media #{$media-mobile-view} {
        @include transition(linear, left, 0.5s);
        background: $white;
        height: 100%;
        overflow: auto;
        padding-bottom: 100px;
        position: fixed;
        top: 0;
        left: -1000px;
        width: 100%;

        &.open {
            left: 0;
        }
    }
}

.mobile-nav-toggle {
    background: transparent;
    border: none;
}

.mobile-top-bar {
    @include flex-v-align-justify;
    height: $mobile-header-height;
    padding: 0 15px;

    @media #{$media-desktop-view} {
        display: none;
    }

    .mobile-nav-toggle {
        height: $mobile-header-height;
        width: $mobile-header-height;

        &.close {
            @include overlay-close;
            margin-left: 6px;
        }
    }
}

@media #{$media-mobile-view} {
    .utility-navigation,
    .universal-navigation {
        > ul {
            @include gray-dark-border-bottom;

            li {
                a {
                    @include font-roboto-medium;
                    font-size: 12px;
                }
            }
        }
    }
}
