﻿/// <reference path="../../cfi-main.scss">

.site-logo {
    display: block;

    .flex-v-align {
        height: $nav-bottom-bar-height;
        justify-content: flex-start;

        @media #{$media-mobile-view} {
            justify-content: center;
        }
    }

    a {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        max-width: 10rem;
    }

    img {
        @include img-responsive;
        margin: auto;
        max-height: 100%;
        width: 100%;
    }
}

@media #{$media-mobile-view} {
    .bottom-bar .site-logo {
        display: none;
    }   
}

.fixed-header {
    .flex-v-align {
        height: $nav-fixed-height;
    }
}
