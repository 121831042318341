﻿/// <reference path="../../cfi-main.scss">

.report,
.refresh {

    .project-detail {

        &-title {
            display: flex;
            flex-direction: column;
            align-items: center;

            h2, p {
                max-width: 65ch;
            }

            h2 {
                color: $green-2021;

                & + * {
                    margin-top: $report-heading-margin;
                }
            }
        }

        &-container {
            margin-top: $gap-xxl;

            @media #{$report-mobile} {
                flex-direction: column;
            }

            h3 {
                font-weight: $font-weight-black;
                text-transform: none;
                color: $bright-green-2021;
                margin-bottom: $gap;
                padding-bottom: 0;

                &::after {
                    display: none;
                }
            }

            p {
                margin-bottom: $gap;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-text,
        &-images {
            flex: 1;
        }

        &-image {
            &.triangle {
                position: relative;
                aspect-ratio: 1 / 1;
                flex: unset;
                max-width: 500px;
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;

                @media #{$report-mobile} {
                    max-width: 300px;
                }
            }

            &-align-left {
                .triangle {
                    mask-image: url(../images/triangle-mask-left.svg);
                    margin-left: auto;
                }
            }

            &-align-right {
                .triangle {
                    mask-image: url(../images/triangle-mask-right.svg);
                }
            }

            img {
                padding: 0;
            }
        }
    }
}