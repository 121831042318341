﻿/// <reference path="../../cfi-main.scss">

.search-overlay {
    background: rgba(0,0,0,.85);  
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3000;

    > .search-overlay-content {
        position: fixed;
        width: 100%;
    }

    .container {
        position: relative;
    }

    .search-overlay-toggle {
        @include overlay-close;
        position: absolute;
        right: 10px;
        top: 77px;
    }

    .search-box {
        @include make-md-column(8);
        @include make-md-column-offset(2);
        @include remove-default-col-padding;
        margin-top: 175px;
        padding-bottom: 15px;

        .input-holder {
            margin-left: 15px;
            margin-right: 15px;
        }

        input {
            @include font-roboto-thin;
            @include gray-border-bottom;
            border-left: 0;
            border-top: 0;
            border-right: 0;
            background: none;
            color: $gray;
            display: block;
            font-size: 48px;
            outline: 0;
            padding: 12px 30px;
            width: 100%;
        }

        button {
            @include site-search-icon($blue, $blue);
            background: none;
            border: 0;
            margin-top: -10px;
            padding: 0;
            position: absolute;
            outline: none;
            right: 15px;
            top: 50%;
        }
    }
}
