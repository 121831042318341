﻿/// <reference path="../cfi-main.scss">

.news-preview-block {
    .grid {
        @include clearfix;

        @media #{$media-desktop-view} {
            @include flex-v-align-justify;
        }

        .news-article-teaser {
            @include flex-end;
            @include make-md-column(4);
            @include background-image-cover(center, center);
            @include transition(ease, background, 0.5s);
            -webkit-backface-visibility: hidden; //prevent safari flickering
            -webkit-transform-style: preserve-3d;
            -webkit-transform: translate3d(0,0,0);
            background-size: 100%;
            border: 0px solid $white;
            height: 300px;

            @media #{$media-desktop-view} {

                &:hover {
                    background-size: 103%;
                    -webkit-backface-visibility: hidden; //prevent safari flickering
                    -webkit-transform-style: preserve-3d;
                    -webkit-transform: translate3d(0,0,0);
                }
            }

            @media #{$media-lg-min} {
                height: 350px;
            }

            &:after {
                background: rgba(0,0,0,.5);
                content: "";
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }

            > div {
                padding: 15px;
                position:relative;
                z-index:2; 

                @media #{$media-xs} {
                    padding-left: 0;
                }

                @media #{$media-desktop-view} {
                    padding: 15px $grid-gutter-width $grid-gutter-width;
                }

                @media #{$media-lg-min} {
                    padding: $grid-gutter-width 80px $grid-gutter-width 15px;
                }
            }

            h2, h5 {
                color: $white;
            }

            h2 {
                font-size: 20px;
                line-height: 1.3em;
                margin-bottom: 20px;

                @media #{$media-desktop-view} {
                    height: 100px;
                    overflow: hidden;
                }
            }

            h5 {
                margin-bottom: 15px;
            }
        }
    }
    /* adjustments for a gutter */
    &.with-gutter {
        .grid {
            @include make-row;

            > div {
                @include make-md-column(4);
                @include clearfix;

                @media #{$media-mobile-view} {
                    margin-top: 15px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .news-article-teaser {
                    @include make-xs-column(12);
                    @include remove-default-col-padding;

                    @media #{$media-desktop-view} {
                        height: 240px;
                    }

                    @media #{$media-lg-min} {
                        height: 300px;
                    }

                    @media #{$media-mobile-view} {
                        padding-left: 15px;
                    }

                    > div {
                        @media #{$media-lg-min} {
                            padding: $grid-gutter-width 80px $grid-gutter-width 30px;
                        }
                    }
                }
            }
        }
    }
}
