﻿/// <reference path="../../cfi-main.scss">

.navigation-with-children {
    .mobile-toggle {
        display: none;
    }

    > ul {
        @include remove-list-format;

        @media #{$media-desktop-view} {
            @include flex-v-align-justify;
            flex-wrap: nowrap;
            height: $nav-bottom-bar-height;

            > li {
                @media #{$media-desktop-view} {
                    margin-right: $grid-gutter-width-half;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                > div {
                    a.page-link {
                        position: relative;
                        z-index: 10;
                    }
                }
            }

            .has-children {
                position: relative;

                &:hover {
                    > a {
                        text-decoration: underline;
                    }

                    ul {
                        @include fade-in(0.4s);
                        left: 0;
                    }
                }
            }

            .nav-items {
                display: block !important; //incase user resizes from mobile with it hidden
            }

            ul {
                @include fade-out(0.4s);
                @include remove-list-format;
                display: block;
                left: -5000%;
                padding-top: 85px;
                position: absolute;
                top: -40px;
                width: 250%;
                z-index: 5;

                li {
                    display: block;
                    margin-bottom: 22px;

                    a {
                        @include font-roboto-condensed-light;
                        font-size: 16px;

                        &:hover {
                            text-decoration: underline;
                        }

                        @media #{$media-lg-min} {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        > li {
            > div {
                a.page-link,
                a.mobile-toggle {
                    @include nav-link-style;
                }
            }
        }
    }

    @media #{$media-mobile-view} {
        > ul {

            > li > div {

                &.has-children {
                    //initally hide
                    .nav-items {
                        display: none;
                    }
                    //restyle top link to match child links
                    a.page-link {
                        display: block;
                        padding: 10px 15px;
                        text-transform: none;
                    }

                    .mobile-toggle {
                        display: block;
                        position: relative;

                        &:after {
                            @include transition(ease, transform, 0.4s);
                            @include budicon;
                            color: $green-2021;
                            content: $bi-var-caret-right;
                            font-size: 20px;
                            margin-top: -10px;
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: rotate(0deg);
                        }

                        &.open {
                            text-decoration: underline;

                            &:after {
                                text-decoration: none;
                                transform: rotate(90deg);
                            }
                        }
                    }
                }

                ul {
                    @include remove-list-format;

                    li {
                        display: block;

                        a {
                            @include font-roboto-condensed-light;
                            display: block;
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
    }
}


.nav-overlay {
    @include fade-out(0.4s);
    background: linear-gradient(to bottom, $white, rgba($white, 0.7));
    height: 0;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;

    &.active {
        @include fade-in(0.4s);
        height: 100%;
    }
}



@media #{$media-desktop-view} {
    .fixed-header {
        .navigation-with-children {
            > ul {
                height: $nav-fixed-height;
            }
        }
    }
}
