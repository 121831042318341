﻿$report-four-panel-head-max-width: 55rem;
$report-four-panel-icon-size: 5rem;

.report-four-panel-info {

    &-head {
        max-width: $report-four-panel-head-max-width;
        text-align: center;
        margin: 0 auto;

        h2 {
            margin-bottom: $gap-md;
        }
    }

    &-container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(0, 1fr));
        gap: $space;
        margin-top: $gap-xl;

        @media #{$report-tablet} {
            margin-top: $gap-lg;
        }

        @media #{$report-mobile} {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    &-item {
        position: relative;
        color: $white;
        padding: $gap-xxl $gap-md $gap-md $gap-md;
        background-color: rgba($green-2021, .7);

        @media #{$report-tablet} {
            padding: $gap-lg $gap $gap $gap;
        }

        h3 {
            @extend %font-subhead;
            margin-bottom: $gap;
        }

        p {
            @extend %weight-bold;
        }
    }

    &-icon {
        width: $report-four-panel-icon-size;
        height: $report-four-panel-icon-size;
        margin-bottom: $gap;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }
}
