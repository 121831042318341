﻿/// <reference path="../report">

.report-double-text-card-block {
    overflow: hidden;

    &-container {
        display: flex;
        justify-content: space-between;
        gap: $sliver;
        width: 100%;
        padding-inline: $report-small-margin;

        @media #{$report-mobile} {
            flex-direction: column;
        }
    }

    &-column {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: $gap-lg $gap-lg $gap-xl $gap-lg;

        @media #{$report-tablet} {
            padding: $gap $gap $gap-lg $gap;
        }

        @each $theme, $color in $report-themes {
            &.theme-#{$theme} {
                background-color: rgba($color, .05);

                h2 {
                    color: $color;
                }
                /*.report-double-text-card-block-btn {
                    background-color: $color;
                    border-color: $color;

                    &:hover {
                        background-color: $white;
                        color: $color;
                    }
                }*/

                &.left::before,
                &.right::after {
                    background-color: rgba($color, .05);
                }
            }
        }

        &.left {
            &::before {
                position: absolute;
                top: 0;
                left: -50vh;
                content: '';
                display: block;
                width: 50vh;
                height: 100%;

                @media #{$report-mobile} {
                    display: none;
                }
            }
        }

        &.right {
            &::after {
                position: absolute;
                top: 0;
                right: -50vh;
                content: '';
                display: block;
                width: 50vh;
                height: 100%;

                @media #{$report-mobile} {
                    display: none;
                }
            }
        }

        h2, p {
            max-width: 26.5rem;
        }
    }

    &-desc {
        margin-top: $gap;
    }

    &-btn {
        margin-top: $gap-lg;

        @media #{$report-mobile} {
            margin-top: $gap-md;
        }
    }
}
