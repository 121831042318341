﻿/// <reference path="../../cfi-main.scss">

.main-navigation {
    > ul {
        @media #{$media-mobile-view} {
            > li > div {
                @include gray-dark-border-bottom;

                &.has-children {
                    //restyle top link to match child links
                    a.page-link {
                        @include font-roboto-condensed-light;
                        font-size: 18px;
                        margin-bottom: 10px;
                        text-transform: none;
                    }

                    ul {
                        li {
                            margin-bottom: 10px;

                            a {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}
