﻿/// <reference path="../../main">

.accordion {
    margin-left: auto;
    margin-right: auto;

    &-header {
        font-size: 3.125rem;
        margin: 0 0 3rem 0;

        @media #{$media-sm-max} {
            font-size: 2.75rem;
            margin: 0 0 2rem 0;
        }
    }

    &-inner {
        position: relative;
        list-style-type: none;
        margin-left: 0;
        padding-left: 0;
    }

    &-item {
        margin-top: 2rem;
        padding-left: 5.625rem;

        @media #{$media-md-max} {
            padding-left: 3rem;
        }

        @media #{$media-sm-max} {
            padding-left: 0;
        }
    }

    &-panel {
        max-width: 100%;
        overflow: hidden;
        transition: height .25s;

        &-container {
            max-width: 40rem;
            margin: 1rem 4.5rem 0 auto;
        }

        &[aria-hidden="true"] {
            height: 0 !important;
        }

        &-content {
            width: 100%;
        }
    }

    &-trigger {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        padding: 0;
        background-color: transparent;
        border: 0;
        border-radius: 0;
        appearance: none;

        &::after {
            position: absolute;
            top: 50%;
            left: 0;
            content: "";
            display: block;
            height: 1px;
            background-color: rgba($green-2021, .25);
            transform: translateY(-50%);
            transition: width .3s ease;

            @media #{$media-xs} {
                display: none;
            }
        }

        &:hover {
            cursor: pointer;

            .accordion-trigger-heading {
                color: $green-2021;
            }
        }

        &:focus-visible {
            outline: 0;

            .accordion-trigger-heading {
                color: $green-2021;
                font-weight: $font-weight-bold;
            }

            .expand-trigger-container {
                outline: 2px solid $green-2021;
            }
        }

        & > * {
            pointer-events: none;
            margin-top: 0;
        }

        &[aria-expanded="false"] {
            &::after {
                width: calc(100% - 9.5rem);

                @media #{$media-xs} {
                    width: calc(100% - 12.25rem);
                }
            }

            .expand-trigger-container {
                width: 7.5rem;
                color: $green-2021;
            }

            .expand-trigger-text {
                display: inline-block;
            }
        }

        &[aria-expanded="true"] {
            &::after {
                width: calc(100% - 4.5rem);

                @media #{$media-xs} {
                    width: calc(100% - 7.25rem);
                }
            }

            .expand-trigger-container {
                width: 2.5rem;
                color: $white;
                background-color: $green-2021;
            }

            .expand-trigger-text {
                display: none;
                opacity: 0;
            }

            .expand-trigger-x {
                transform: rotate(315deg);
            }
        }

        &-heading {
            position: relative;
            max-width: 65%;
            font-size: 2.5rem;
            font-weight: $font-weight-light;
            color: $black;
            padding-right: 2rem;
            background-color: $white;
            z-index: 1;

            @media #{$media-xs} {
                max-width: 100%;
                font-size: 1.875rem;
                padding-right: 0;
            }
        }

        &-desktop {
            display: flex;

            @media #{$media-xs} {
                display: none;
            }

            .expand-trigger-container {
                display: flex;

                @media #{$media-xs} {
                    display: none;
                }
            }
        }

        &-mobile {
            display: none;

            &::after {
                display: none;

                @media #{$media-xs} {
                    position: absolute;
                    top: 50%;
                    left: 2.75rem;
                    content: "";
                    display: block;
                    height: 1px;
                    background-color: rgba($green-2021, .25);
                    transform: translateY(-50%);
                    transition: width .3s ease;
                }
            }

            @media #{$media-xs} {
                display: flex;
            }

            .expand-trigger-container {
                display: none;

                @media #{$media-xs} {
                    display: flex;
                }
            }
        }
    }
}

.expand {

    &-trigger {

        &-container {
            justify-content: center;
            align-items: center;
            height: 2.5rem;
            padding: 0;
            border: 2px solid $green-2021;
            border-radius: 999em;
            appearance: none;
            box-shadow: $container-box-shadow;
            z-index: 1;
            overflow: hidden;
            transition: width 0.3s ease, color 0.3s ease, background-color 0.3s ease;

            & > * {
                pointer-events: none;
            }
        }

        &-text {
            display: block;
            font-size: .675rem;
            font-weight: $font-weight-black;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            color: $green-2021;
            padding-right: .625rem;
            transition: opacity 0.3s ease;
        }

        &-x {
            display: block;
            font-size: 2rem;
            font-weight: $font-weight-normal;
            transform-origin: center;
            transition: transform 0.3s ease;
        }
    }
}
