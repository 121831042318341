﻿/// <reference path="../../cfi-main">

.card {
    &-item {
        padding: 2.5rem;
        background: $white;
        box-shadow: $container-box-shadow;
        transition: 0.3s all;

        &[data-watermark]::before {
            left: 0;
            transform: none;
            -webkit-text-stroke: 3px $light-blue;
            color: $white;
            opacity: 0.1;
            white-space: nowrap;
        }

        .accent-alt {
            color: $green-2021;

            @media #{$media-mobile-view} {
                color: rgba($white, .75);
            }
        }

        &:not(.is-active) {
            color: $black;
            opacity: 0.7;
        }

        &.is-active:not(.is-origin),
        &.is-target {
            color: $white;
            background-color: $green-2021;
            opacity: 1;

            &[data-watermark]::before {
                -webkit-text-stroke: 0;
            }

            .accent-alt {
                color: rgba($white, .75);
            }
        }

        @media #{$media-mobile-view} {
            color: $white;
            background-color: $green-2021;
            opacity: 1;
        }
    }

    &-index {
        font-size: 1rem;
        line-height: 1.75;
        margin-bottom: 3rem;
    }

    &-inner {

        .header {
            margin-bottom: 2rem;

            [role="heading"] {
                font-size: 2.5rem;
                font-weight: $font-weight-light;
                line-height: 1.2;
            }

            strong {
                font-weight: $font-weight-bold;
            }
        }
    }
}
