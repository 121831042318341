﻿/// <reference path="../../cfi-main.scss">

.universal-navigation { /*wrapping it to override styles from the nav*/
    .language-selector-divider {
        color: $white;
        margin-left: 40px;

        @media #{$media-mobile-view} {
            display: none;
        }
    }

    @media #{$media-desktop-view} {

        .language-selector {
            height: ($nav-top-bar-height - 1); /*have it end before the border*/
            line-height: $nav-top-bar-height;
            margin-top: -1px;
            margin-left: 20px;
            position: relative;
            z-index:20; 

            .current-language {
                @include transition-all-default;
                display: block;
                height: 100%;
                padding: 0 25px 0 15px;
                overflow: hidden;

                &:after {
                    @include budicon;
                    @include transition-all-default;
                    color: $white;
                    content: $bi-var-caret-down;
                    font-size: 10px;
                    margin-left: 5px;
                    transform: rotate(180deg);
                    margin-top: -7px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                }

                &.collapsed {
                    &:after {
                        margin-top: -5px;
                        transform: rotate(0deg);
                    }
                }

                &:hover {
                    cursor: pointer;
                    text-decoration: none;
                }

                &:hover,
                &:not(.collapsed) {
                    background: $blue;
                }
            }

            .language-list {
                @include remove-list-format;
                @include color-links($white, $white);
                background: $blue;
                position: absolute;
                right: 0;
                width: 200%;

                li {
                    &.active {
                        display: none;
                    }
                }

                a {
                    @include font-roboto-light;
                    display: block;
                    font-size: 15px;
                    line-height: 17px;
                    padding: $nav-link-padding;
                    text-align: left;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    @media #{$media-mobile-view} {
        .language-selector {
            @include gray-border-top;

            .current-language {
                @include font-roboto-condensed;
                font-size: 16px;
                position: relative;

                &:hover {
                    cursor: pointer;
                }

                &:after {
                    @include transition(ease, transform, 0.4s);
                    @include budicon;
                    color: $white;
                    content: $bi-var-caret-right;
                    font-size: 20px;
                    margin-top: -10px;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: rotate(0deg);
                }

                &:not(.collapsed) {
                    text-decoration: underline;

                    &:after {
                        text-decoration: none;
                        transform: rotate(90deg);
                    }
                }
            }

            .language-list {
                @include remove-list-format;

                li {
                    a {
                        @include font-roboto-condensed-light;
                        color: $white;
                        display: block;
                        font-size: 18px;
                        margin-bottom: 10px;
                        padding: 10px 15px;
                        text-transform: none;

                        &:hover {
                            cursor: pointer;
                        }

                        &.active {
                            color: $gray;
                        }
                    }
                }
            }
        }
    }
}
