﻿/// <reference path="../../cfi-main">
/**
 * When the native `<dialog>` element is supported, the overlay is implied and
 * can be styled with `::backdrop`, which means the DOM one should be removed.
 *
 * The `data-a11y-dialog-native` attribute is set by the script when the
 * `<dialog>` element is properly supported.
 *
 * Feel free to replace `:first-child` with the overlay selector you prefer.
 */
[data-a11y-dialog-native] > :first-child {
    display: none;
}

/**
 * When the `<dialog>` element is not supported, its default display is `inline`
 * which can cause layout issues. This makes sure the dialog is correctly
 * displayed when open.
 */
dialog[open] {
    display: block;
}

/**
 * When the native `<dialog>` element is not supported, the script toggles the
 * `aria-hidden` attribute on the container. If `aria-hidden` is set to `true`,
 * the container should be hidden entirely.
 *
 * Feel free to replace `.dialog-container` with the container selector you
 * prefer.
 */
.dialog-container[aria-hidden='true'] {
    display: none;
}


// Expected DOM structure from a11y-dialog documentation:


// Main container related notes:
// - It can have a different id than `main`, however you will have to pass it as a second argument to the A11yDialog instance. See further down.

// <div id="main" >

// Here lives the main content of the page.

// </div >

// Dialog container related notes:
// - It is not the actual dialog window, just the container with which the script interacts.
// - It can have a different id than `my-accessible-dialog`, but it needs an `id`
// anyway.

// <div id="my-accessible-dialog" >

// Overlay related notes:
// - It has to have the `tabindex="-1"` attribute.
// - It doesn’t have to have the `data-a11y-dialog-hide` attribute, however this is recommended. It hides the dialog when clicking outside of it.

// <div tabindex="-1" data-a11y-dialog-hide > </div >

// Dialog window content related notes:
// - It is the actual visual dialog element.
// - It doesn’t have to be a `<dialog > ` element, it can be a `<div > ` element with the `dialog` role (e.g. `<div role="dialog">`).
// - It doesn’t have to have the `aria-labelledby` attribute however this is recommended. It should match the `id` of the dialog title.

// <dialog aria-labelledby="dialog-title" >

// Closing button related notes:
// - It does have to have the `type="button"` attribute.
// - It does have to have the `data-a11y-dialog-hide` attribute.
// - It does have to have an aria-label attribute if you use an icon as content.

// <button type="button" data-a11y-dialog-hide aria-label="Close this dialog window" >
// &times;
// </button >

// Dialog title related notes:
// - It should have a different content than `Dialog Title`.
// - It can have a different id than `dialog-title`.

// <h1 id="dialog-title" > Dialog Title</h1 >

// Here lives the main content of the dialog.

// </dialog >
// </div >

$modal-prefix: 'adage-';
// STRUCTURE & LAYOUT
.#{$modal-prefix}modal {
    &-open {
        overflow: hidden;
    }

    &-wrapper {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
        }

        background-color: rgba(255,255,255, .8);
        overflow-x: hidden;
        position: fixed;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 150;
        transition: 0.3s ease all;
        overflow-y: auto;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: 750px;
        margin: auto;
        top: 0;
        display: flex;
        align-items: center;

        @media #{$media-mobile-view} {
            width: 100%;
            bottom: auto;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $v-spacing-sm;
    }

    &-inner {
        position: relative;
        background-color: white;
        color: $black;
        padding: $v-spacing-sm;
        width: 100%;
    }
}

// STYLING
.#{$modal-prefix}modal {
    &-heading {
        // @extend %h4;
    }

    &-wrapper {
        padding: $v-spacing-sm;
    }

    &-inner {
        &-full-bleed {
            padding: 0;
        }
    }
}

// CLOSE BUTTON
.#{$modal-prefix}modal {
    &-close {
        border: 0;
        background: transparent;
        padding: 0;
        margin: 0;
        width: 1.25em;
        height: 1.25em;
        min-width: 0;
        position: relative;
        display: inline-block;
        line-height: 1;

        &::before,
        &::after {
            content: '';
            display: block;
            width: 1.5rem;
            height: 2px;
            background-color: $blue;
            position: absolute;
            left: 50%;
            top: 50%;
        }

        &::before {
            transform: translateX(-50%) rotate(-45deg);
        }

        &::after {
            transform: translateX(-50%) rotate(45deg);
        }

        flex-shrink: 0;

        @media #{$media-mobile-view} {
            right: 0;
        }
    }
}
