﻿/// <reference path="../cfi-2021-refresh.scss">

.container {
    width: 100%;
    max-width: $large-max-width;

    @media #{$media-desktop-view} {
        padding-left: $grid-gutter-width-double;
        padding-right: $grid-gutter-width-double;
    }
}