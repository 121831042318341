﻿/// <reference path="../cfi-main.scss">

.news-article-page {
    @include page-margin-bottom;

    .general-page-hero {
        @include v-spacing-bottom;

        h1 {
            @include v-spacing-top;
        }
    }

    .article-layout {
        .left {
            section {
                margin-bottom: ($v-spacing-sm);
            }

            h6 {
                margin-bottom: ($v-spacing-sm / 4);

                span {
                    font-weight: $font-weight-light;
                }
            }

            .social-share {
                margin-top: 5px;
            }
        }
    }

    .article-video {
        margin-bottom: $v-spacing-lg;
        position: relative;

        .video {
            background-color: $video-background-color;
            display: block;
            height: auto;
            width: 100%;
        }
    }

    .article-navigation {
        @include make-row;
        @include v-spacing-top;
        margin-bottom: $v-spacing-lg;

        > div {
            @include make-sm-column(6);

            > a {
                @include btn-border-collapsed;
                text-align: center;

                @media #{$media-xs} {
                    display: block;
                }

                @media #{$media-sm-min} {
                    width: 160px;
                }
            }

            &.prev {
                @media #{$media-xs} {
                    margin-bottom: ($v-spacing-sm);
                }

                @media #{$media-sm-min} {
                    text-align: right;
                }

                > a {
                    @include btn-prev;
                }
            }

            &.next {
                > a {
                    @include btn-next; 
                }
            }
        }
    }

    .related-posts {
        @include module-padding;
        background: $lightest-gray;

        .right {
            @include make-md-column-offset(1); 
        }

        .news-preview-block .grid {
            > div {
                @include make-md-column(6);
                
                .news-article-teaser {
                    &:hover {
                        border-color:$lightest-gray;
                    }
                }
            }
        }
    }
}
