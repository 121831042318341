﻿/// <reference path="../cfi-main.scss">
@import "../../libs/slick-slider/slick.scss";

.vision-and-values-grid-block {
    @include background-image-cover(center, center);
    @include module-padding;
    @include transition(background, ease, 1s);
    height: 700px;
    position: relative;

    @media #{$media-mobile-view} {

        > .container {
            padding: 0;

            > .row {
                margin: 0;
            }
        }
    }

    @media #{$media-desktop-view} {
        height: 660px;
    }

    .overlay {
        @include banner-overlay;
    }

    .left-column {
        @include make-md-column(5);

        @media #{$media-mobile-view} {
            margin-bottom: $v-spacing-lg;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .right-column {
        @include make-md-column(6);
        @include make-md-column-offset(1);

        @media #{$media-mobile-view} {
            bottom: 0;
            overflow: hidden;
            padding: 0 0 $v-spacing-lg $grid-gutter-width-half;
            position: absolute;
            width: 100%;
        }
    }

    .blue-bordered-box {
        @include box-with-border($blue, $white);

        @media #{$media-desktop-view} {
            margin-top: 130px;
        }

        .arrow-link {
            &:after,
            &:hover {
                color: $white;
            }
        }

        h2 {
            @media #{$media-mobile-view} {
                padding: 15px;
            }
        }

        .content {

            @media #{$media-desktop-view} {
                min-height: 200px;
            }

            @media #{$media-mobile-view} {
                padding: 15px;
            }

            ul {
                margin: 0;
                padding: 0 0 0 20px;

                li {
                    margin: 5px 0 0;

                    &:first-child {
                        margin: 0;
                    }
                }
            }

            p {
                margin-bottom: $v-spacing-xs;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    #vision-values-carousel {
        margin-bottom: 0;

        @media #{$media-desktop-view} {
            @include make-row;
        }

        .item {
            @include make-md-column(4);
            outline: none;

            &:active,
            &:focus {
                outline: none;
            }

            @media #{$media-mobile-view} {
                height: 206px;
                padding: 0;
                width: 236px;
            }

            @media #{$media-mobile-xs} {
                height: 135px;
                width: 165px;
            }

            @media #{$media-desktop-view} {
                height: 132px;
                margin-bottom: $grid-gutter-width;
            }

            @media #{$media-lg-min} {
                height: 165px;
            }

            &.slick-cloned {
                display: none !important;
            }

            > div {
                @include flex-end;
                @include transparent-black-bg(0.8);
                @include box-with-border($blue, $white);
                @include transition(background, ease, 0.4s);
                color: $white;
                font-size: 16px;
                height: 100%;
                padding: 15px;
                position: relative;

                @media #{$media-mobile-view} {
                    margin-right: 30px;
                }

                @media #{$media-lg-min} {
                    font-size: 21px;
                }

                i {
                    @include transition-all-default;
                    bottom: 0;
                    display: block;
                    height: 25px;
                    left: 0;
                    margin: auto;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 25px;

                    &:after {
                        @include budicon;
                        content: $bi-circle-plus;
                        font-size: 25px;
                    }
                }

                &.active {
                    background: rgba(0,86,184,0.6);

                    i {
                        &:after {
                            content: $bi-circle-minus;
                        }
                    }
                }
            }
        }

        .slick-dots {
            bottom: -($v-spacing-lg -10px);
            left: 0;

            li {
                background: $white;
                border: 2px solid $white;
                border-radius: 50%;
                height: 15px;
                width: 15px;

                &.slick-active {
                    background: rgba(0,86,184,0.9);
                }
            }
        }
    }
}
