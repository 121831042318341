﻿/// <reference path="../cfi-main.scss">

.paragraph-block {
    background: $white;

    &.gray-bg {
        background: $lightest-gray;
    }

    .container {
        > div {
            &.content-wrapper {
                padding: $grid-gutter-width;
                text-align: center;

                @media #{$media-desktop-view} {
                    padding: 80px;
                }
            }

            &.accordion {
                margin-bottom: $grid-gutter-width;

                @media #{$media-sm-min} {
                    margin: 0 auto $grid-gutter-width-double;
                    width: 75%;
                }

                @media #{$media-lg-min} {
                    width: 60%;
                }
            }
        }
    }

    .paragraph {
        @include h3;
        color: $dark-gray;
        line-height: 1.6em;
        margin-bottom: 20px;
        padding-bottom: 20px;
        position: relative;

        @media #{$media-desktop-view} {
            line-height: 2em;
        }

        &:after {
            background: $dark-gray;
            bottom: 0;
            content: " ";
            display: block;
            height: 1px;
            left: 0;
            margin: auto;
            position: absolute;
            right: 0;
            width: 25px;
        }
    }

    p + p {
        margin-top: 20px;
    }

    &.bg-image {
        @include background-image-cover(center,center);

        @media #{$media-desktop-view} {
            padding: $grid-gutter-width-double 0;
        }

        .container {
            @media #{$media-mobile-view} {
                padding: 0;
            }

            > .content-wrapper {
                @include thick-blue-box-border;
                @include transparent-black-bg(0.25);
            }
        }

        p {
            color: $white;
        }

        .paragraph {
            color: $white;

            &:after {
                background: $white;
            }
        }

        .arrow-link {
            color: $white;

            &:after {
                color: $white;
            }

            &:hover {
                color: $blue;

                &:after {
                    color: $blue;
                }
            }
        }
    }

    &.show-seal {

        .container {
            position: relative;

            .content-wrapper {
                padding-bottom: 250px;

                @media #{$media-desktop-view} {
                    padding-bottom: 200px;
                }
            }
        }

        .seal {
            position: absolute;

            @media #{$media-mobile-view} {
                bottom: 30px;
                left: 50%;
                margin-left: -90px;
            }

            @media #{$media-md-min} {
                bottom: -30px;
                left: 75px;
            }

            img {
                height: 180px;
                width: auto;

                @media #{$media-md-min} {
                    height: 250px;
                }

                @media #{$media-lg-min} {
                    height: 290px;
                }
            }
        }
    }
}

.accordion-right,
.text-intro {
    @include module-padding;

    .left,
    .right {
        @include make-md-column(6);
    }

    .left {
        @media #{$media-desktop-view} {
            padding-right: 30px;
        }
    }

    .right {
        @media #{$media-mobile-view} {
            margin-top: $v-spacing-sm;
        }
    }

    .arrow-link {
        margin-top: 15px;
    }

    @media #{$media-desktop-view} {
        .section-header h4,
        .section-header .h4-style {
            margin-bottom: 120px;
        }
    }
}

.text-intro {
    @media #{$media-mobile-view} {
        padding: $v-spacing-sm 0;
    }

    .right {
        @media #{$media-desktop-view} {
            text-align: right;
        }
    }
}
