﻿/// <reference path="cfi-main.scss">

$_epiform-max-width: 53.125rem;
$_epiform-input-height: 3.125rem;

.EPiServerForms {
    width: 100%;
    max-width: $_epiform-max-width;
    margin-inline: auto;
    padding-inline: $grid-gutter-width-half;

    .Form__Status__Message.hide {
        display: none !important;
    }

    .Form__Title {
        @include h3;
        margin-bottom: $gap-sm;
    }

    .Form__Description:empty {
        display: none !important;
    }

    div.Form__Element {
        display: flex;
        flex-direction: column;
    }

    .Form__Element__Caption {
        font-weight: $font-weight-bold;
    }

    .FormTextbox__Input {
        border: 1px solid $light-gray;
    }

    input.FormTextbox__Input {
        height: $_epiform-input-height;
    }

    textarea.FormTextbox__Input {
        height: calc(#{$_epiform-input-height} * 3);
    }

    .Form__Element__ValidationError {
        font-size: .85rem;
        font-weight: $font-weight-semibold;
    }

    .FormSubmitButton {
        @include arrow-link-green;
        background-color: transparent;
        border: 0;
        appearance: none;
    }
}