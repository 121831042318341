﻿/// <reference path="../cfi-main.scss">

.link-blocks {
    @include general-page-block-spacing;
    @include module-padding;

    &-container {
        display: flex;
        justify-content: center;

        @media #{$media-xs} {
            display: block;
        }
    }

    &-item {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: $green-2021;
        padding: calc(#{$gap-lg} * 2) $gap-lg $gap-lg $gap-lg;
        background-color: $light-bright-green-2021;
        overflow: hidden;

        @media #{$media-md-max} {
            padding: calc(#{$gap-md} * 2) $gap $gap $gap;
        }

        &:hover {
            .link-blocks-bg {
                opacity: .3;
            }
        }

        &:last-child {
            color: $white;
            background-color: $green-2021;

            @media #{$media-xs} {
                margin-top: $gap;
            }
        }
    }

    &-text {
        margin-bottom: $space;
    }

    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        transition: opacity .3s;

        & ~ * {
            position: relative;
        }
    }
}
