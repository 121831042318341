﻿$report-link-list-item-height: 5.25rem;
$report-link-list-item-epxanded-height: 25rem;
$report-link-list-image-epxanded-height: calc(#{$report-link-list-item-epxanded-height} - #{$report-link-list-item-height});
$report-link-list-icon-size: 2rem;

.report-link-list {

    &-mobile-img {
        display: none;

        @media #{$report-tablet} {
            display: block;
            aspect-ratio: 3 / 1;
            margin-bottom: $gap-sm;
        }

        @media #{$report-mobile} {
            aspect-ratio: 2 / 1;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-head {
        margin-bottom: $gap;
    }

    &-tag {
        display: inline-block;
        text-transform: uppercase;
        color: $white;
        margin-bottom: $gap-sm;
        padding: $sliver $gap-sm;
        background-color: $green-2021;
    }

    &-items {
        padding: 0;
    }

    &-item {
        list-style: none;
    }

    &-link {
        position: relative;
        display: block;
        height: $report-link-list-item-height;
        text-decoration: none;
        color: $green-2021;
        margin-bottom: $gap-sm;
        transition: color $transition;

        @media #{$report-mobile} {
            height: unset;
        }

        &:hover {
            color: $white;

            .report-link-list-link-container {
                background-color: $green-2021;
            }

            .report-link-list-link-download {
                &::after {
                    filter: invert(1);
                }
            }
        }

        &.has-image {
            transition: height $transition, color $transition;

            &:hover {
                height: $report-link-list-item-epxanded-height;

                .report-link-list-link-image {
                    height: $report-link-list-image-epxanded-height;
                }
            }
        }

        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $gap;
            background-color: $report-med-gray;
            transition: background-color $transition;

            @media #{$report-tablet} {
                padding: $gap $gap-sm;
            }

            @media #{$report-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: $gap;
            }

            span {
                display: block;
            }
        }

        &-download {
            position: relative;
            text-transform: uppercase;
            padding-right: $gap-lg;

            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                width: $report-link-list-icon-size;
                height: $report-link-list-icon-size;
                content: '';
                background-image: url(../images/report-download-icon.png);
                background-size: contain;
                background-repeat: no-repeat;
                transform: translateY(-50%);
                transition: filter $transition;
            }
        }

        &-image {
            position: absolute;
            top: $report-link-list-item-height;
            left: 0;
            width: 100%;
            height: 0;
            transition: height $transition;
            z-index: -1;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
