﻿$report-graphic-icon-height: 4.125rem;
$report-graphic-item-mobile-width: 18.75rem;

.report-graphic-info {
    text-align: center;
    padding: $gap-lg 0 $gap-xxl 0;
    background: rgba($green-2021, .05);
    background: linear-gradient(180deg, $white 0%, rgba($green-2021, .05) 100%);

    &-head {
        margin-bottom: $gap-xl;

        p {
            @extend %font-xl;
            color: $green-2021;
        }
    }

    &-title {
        padding-bottom: $gap-lg;
    }

    &-body {
        display: flex;
        justify-content: center;
        gap: $gap;

        @media #{$report-mobile} {
            flex-direction: column;
            align-items: center;
        }
    }

    &-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: $gap-sm;

        @media #{$report-mobile} {
            max-width: $report-graphic-item-mobile-width;
        }

        p {
            @extend %weight-bold;
        }
    }

    &-icon {
        width: 100%;
        height: $report-graphic-icon-height;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
