﻿/// <reference path="../../cfi-main.scss">

.utility-navigation {
    > ul {

        @media #{$media-mobile-view} {
            > li > div {
                a.page-link {
                    @include font-roboto-medium;
                    font-size: 12px;
                }

                &.has-children {
                    .mobile-toggle {
                        @include font-roboto-medium;
                        font-size: 12px;
                    }

                    a.page-link {
                        @include font-roboto-condensed-light; 
                        font-size: 16px; 
                    }

                    ul li {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}
