﻿/// <reference path="../../cfi-main.scss">

.locations-landing-location-type-filters.horizontal-filter-container {
    position: relative;

    ul {
        margin-bottom:30px;
        @media #{$media-desktop-view} {
            padding-left: 100px;
        }
    }

    h3 {
        @media #{$media-desktop-view} {
            @include font-roboto-medium;
            font-size: 15px;
            left: 10px;
            line-height: 15px;
            padding: 20px 0;
            position: absolute;
            top: 0;
        }

        @media #{$media-mobile-view} {
            margin: 30px 0 15px;
        }
    }
}


