﻿/// <reference path="../cfi-main.scss">

.search-page {

    .container {
        @include clearfix;
    }

    .search-page-input {
        @include make-md-column(5);
        @include remove-default-col-padding;

        @media #{$media-desktop-view} {
            float: none;
        }
    }
     
    .search-box {
        border: none;
        @include on-page-search-input;

        .input-holder {
            @include thick-blue-box-border;
        }
    }
}
