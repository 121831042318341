﻿/// <reference path="../cfi-main.scss">

.article-grid {
    @include general-page-block-spacing;
    @include module-padding;
    background-color: $lightest-gray;

    &-header {
        text-align: center;
        color: $green-2021;
        margin-bottom: -$gap;

        @media #{$media-xs} {
            margin-bottom: -$gap-lg;
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media #{$media-md-max} {
            padding-right: $gap;
            padding-left: $gap;
        }

        @media #{$media-xs} {
            display: block;
        }
    }

    &-item {
        flex: 0 0 calc(50% - #{$gap});
        margin-top: $gap-xl;
        background-color: $white;

        @media #{$media-md-max} {
            flex: 0 0 calc(50% - #{$gap-sm});
        }
    }

    &-body {
        max-width: $max-text-width;
        padding: $gap;

        & > * {
            margin-bottom: $gap-sm;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-title {
        margin-bottom: $gap-sm;
    }

    &-image {
        width: 100%;
        aspect-ratio: 2.5 / 1;
        margin-bottom: $gap-sm;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-fit-position: center;
        }
    }
}
