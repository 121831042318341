﻿/// <reference path="../cfi-main">

// 2023 Homepage redesign
h1.refresh-home {
    &-hero-heading {
        max-inline-size: 23ch;
        text-wrap: balance;
        font-weight: $font-weight-semibold;

        &:first-line {
            font-weight: $font-weight-normal;
        }

        & + * {
            margin-top: $gap;
        }
    }
}

// Focal point alignment options, for sustainability home-banner but for use wherever needed
$_h-align: 'left', 'center', 'right';
$_v-align: 'top', 'center', 'bottom';

@each $_h in $_h-align {
    @each $_v in $_v-align {
        .focal-point-#{$_h} {
            &-#{$_v} {
                background-position: #{$_h} #{$_v};

                img {
                    object-position: #{$_h} #{$_v};
                }
            }
        }
    }
}

%arrow-button {
    color: $report-environment;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    &::after {
        content: ' ' '\279C';
        color: currentColor;
        font-weight: 400;
    }

    &:hover,
    &:focus {
        background-color: transparent;
        opacity: 0.8;
    }
}

.refresh {
    %h2 {
        font-size: 2rem;

        @media #{$report-tablet} {
            font-size: 1.875rem;
        }

        @media #{$report-mobile} {
            font-size: 1.75rem;
        }
    }

    @import '_blocks.scss';
}
