﻿/// <reference path="../../cfi-main.scss">

.footer-legal-links {
    ul {
        @include remove-list-format;

        li {
            @include color-links($gray, $blue);

            display: inline-block;
            vertical-align: middle;

            @media #{$media-mobile-view} {
                margin-right: 20px;
            }

            @media #{$media-desktop-view} {
                margin-left: $grid-gutter-width;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}
