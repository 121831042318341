﻿/// <reference path="../../cfi-main.scss">

.general-page-hero {
    margin-bottom: $v-spacing-sm;

    @media #{$media-desktop-view} {
        margin-bottom: $v-spacing-lg;
    }

    .background {
        @media #{$media-mobile-view} {
            background: $white;
            height: $mobile-header-height;
        }

        @media #{$media-desktop-view} {
            @include transparent-black-bg(0.8);
            height: $nav-total-height;
        }
    }

    h1 {
        margin-top: $v-spacing-sm;
    }

    &.has-breadcrumb {
        h1 {
            @media #{$media-desktop-view} {
                margin-top: 105px;
            }
        }
    }
}

.general-page-hero-bg {
    @include background-image-cover(right, center);
    height: 500px;
    margin-bottom: $v-spacing-sm;

    @media #{$media-mobile-view} {
        height: 250px;
    }

    .overlay {
        background: transparent;
        background: -moz-linear-gradient(top, rgba(0,0,0,0.75) 0%, rgba(39,39,39,0.5) 15%, rgba(255,255,255,0) 99%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.75) 0%,rgba(39,39,39,0.5) 15%,rgba(255,255,255,0) 99%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0.75) 0%,rgba(39,39,39,0.5) 15%,rgba(255,255,255,0) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        height: 100%;
        padding-top: 80px;

        @media #{$media-desktop-view} {
            padding-top: $nav-total-height;
        }
    }

    h1 {
        color: $white;
    }

    .container {
        @include flex-v-align;
        align-items: flex-end;
        height: 100%;
        padding-bottom: $grid-gutter-width;

        @media #{$media-desktop-view} {
            padding-bottom: 80px;
        }
    }

    &.has-breadcrumb {
        @media #{$media-desktop-view} {
            margin-bottom: 134px; //breadcrumb is 74px + 60px
        }
    }
}
