﻿/// <reference path="../../cfi-main.scss">

.search-results {
    @include clearfix;
    @include v-spacing-bottom;
    @include v-spacing-top; 

    .results-column {
        @include make-md-column(8);
        @include remove-default-col-padding;

        .result-block {
            @include v-spacing-top;

            &:first-child {
                margin-top: 0;
            }

            h3, p {
                @include color-links($blue, $black); 
                margin-bottom: 5px;
            }
             
            .url {
                @include color-links($green, $blue);  
            }
        }
    }
}
