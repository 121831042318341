﻿/// <reference path="../../cfi-main.scss">

.search-box {
    @include transition-all-default;
    border: 1px solid transparent;

    .search-error-box {
        @include fade-in(0.4s);
        @include transition-all-default; 
        background: rgba(0,86,184, .85);
        color: $white;
        display: block !important; //override angular
        height: 55px;
        padding: 15px 10px;

        &.ng-hide {
            @include fade-out(0.4s);
            @include transition-all-default;
            height: 0;
        }

        &.ng-hide-add-active,
        .ng-hide-remove-active {
            opacity: 0;
            visibility: hidden;
        }
    }

    &.has-error {
        border-color: $blue;
    }

    .input-holder {
        position: relative;
    } 

    input {
        -webkit-appearance: none; //safari ios 
        border-radius: 0; 
    }
}
