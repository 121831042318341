﻿.report-two-link-download {
    padding: $gap-md 0;
    background-color: $green-2021;

    &-container {
        display: flex;
        justify-content: center;

        @media #{$report-mobile} {
            flex-direction: column;
            align-items: center;
            gap: $gap-sm;
            text-align: center;
        }

        &::after {
            display: none;
        }
    }

    &-link {
        text-transform: uppercase;
        color: $white;

        &:nth-child(2) {

            &::before {
                content: '|';
                margin: 0 $gap;

                @media #{$report-mobile} {
                    display: none;
                }
            }
        }
    }
}
